export enum Creditor {
    GET_CREDITORS_START = "GET_CREDITORS_START",
    GET_CREDITORS_SUCCESS = "GET_CREDITORS_SUCCESS",
    GET_CREDITORS_FAIL = "GET_CREDITORS_FAIL",

    GET_CREDITOR_BY_NUMBER_START = "GET_CREDITORS_BY_NUMBER_START",
    GET_CREDITORS_BY_NUMBER_SUCCESS = "GET_CREDITORS_BY_NUMBER_SUCCESS",
    GET_CREDITORS_BY_NUMBER_FAIL = "GET_CREDITORS_BY_NUMBER_FAIL",

    SET_CREDITOR_STATUS = "SET_CREDITOR_STATUS"
}