import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IO from "us.collection.input-output";

interface ICnt_ATGHome extends RouteComponentProps {}

const Cnt_ATGHome: React.FC<ICnt_ATGHome> = (props) => {
  return (
    <>
      <IO.Components.ATGHome.ATGHome {...props} />
    </>
  );
};

export default withRouter(Cnt_ATGHome);
