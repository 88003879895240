export enum Invoice {
    GET_WORKFLOW_STATES_START = "GET_WORKFLOW_STATES_START",
    GET_WORKFLOW_STATES_SUCCESS = "GET_WORKFLOW_STATES_SUCCESS",
    GET_WORKFLOW_STATES_FAIL = "GET_WORKFLOW_STATES_FAIL",

    REGISTER_INVOICE_START="REGISTER_INVOICE_START",
    REGISTER_INVOICE_SUCCESS="REGISTER_INVOICE_SUCCESS",
    REGISTER_INVOICE_FAIL="REGISTER_INVOICE_FAIL",

    RESET_CLAIMS_DATA="RESET_CLAIMS_DATA",

    SEARCH_POSTALCODE_START="SEARCH_POSTALCODE_START",
    SEARCH_POSTALCODE_SUCCESS="SEARCH_POSTALCODE_SUCCESS",
    SEARCH_POSTALCODE_FAIL="SEARCH_POSTALCODE_FAIL",

    SEARCH_MUNICIPALITY_START="SEARCH_MUNICIPALITY_START",
    SEARCH_MUNICIPALITY_SUCCESS="SEARCH_MUNICIPALITY_SUCCESS",
    SEARCH_MUNICIPALITY_FAIL="SEARCH_MUNICIPALITY_FAIL",
}