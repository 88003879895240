import { initialState } from "us.collection.input-output/reducers/ATG/State";
import { ATG } from "us.collection.input-output/constants/Actions";

const {
  SEARCH_ATG,
  SEARCH_ATG_SUCCESS,
  SEARCH_ATG_FAIL,
  GET_ATG,
  GET_ATG_SUCCESS,
  GET_ATG_FAIL,
} = ATG;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SEARCH_ATG:
      return Object.assign({}, state, {
        atgs: {
          data: [],
          isFetching: true,
        },
      });
    case SEARCH_ATG_SUCCESS:
      return Object.assign({}, state, {
        atgs: {
          data: payload.data,
          isFetching: false,
        },
      });
    case SEARCH_ATG_FAIL:
      return Object.assign({}, state, {
        atgs: {
          data: [],
          isFetching: false,
        },
      });
    default:
      return state;
  }
};
