import React from "react";
import { FieldArray, useField } from "formik";
import { CloseOutlined, PlusOutlined } from "us.icons";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IntlCurrency } from "us.common/functions";
import { dateFormats } from "us.collection.input-output/components/RegisterInvoice/Constants";
import { ITransactionSetUp } from "us.collection.input-output/components/RegisterInvoice/Interfaces";
import {getTransactionType,isEnabledBODate} from './Function'
import { InvoiceType } from "us.collection.input-output/constants";

const TransactionSetUp = (props: ITransactionSetUp) => {
  const { t } = useTranslation();
  const [, meta] = useField("transations");
  const error = meta?.error;

  const {
    $Input,
    $Row,
    $Col,
    $Popconfirm,
    $Button,
    $Select,
    $DatePicker,
    $Divider,
    $InputAmount,
    $Tooltip
  } = Common.Components;

  const {
    currentDateFormat,
    values,
    currentCurrency,
    currentLanguage,
    openTransactionType,
    transactionTypes,
    rest,
    disabled,
  } = props;

  const {transations,registerAs}=values;

  const initials = {
    tType: "",
    tNumber: "",
    tAmount: "",
    tKid: "",
    tInfo: "",
    tDate: moment().format(dateFormats.req),
    tDueDate: moment().add(14, "days").format(dateFormats.req),
    tDWDate: "",
    tInterestDate: "",
    tInterestRate: "",
    tBODate:''
  };

  const isCaseRegistration:boolean=registerAs?.toLowerCase()===InvoiceType.Case

  const onChangeVoucherDate = (date: any, rest: any, index: number) => {
    rest.setFieldValue(`transations[${index}].tDate`, date);
    if (moment(date).isValid()) {
      rest.setFieldValue(
        `transations[${index}].tDueDate`,
        moment(date).add(14, "days")
      );
      setTimeout(() =>
        rest.setFieldTouched(`transations[${index}].tDueDate`, true)
      );
    }
  };
  const onDateChange = (
    type: "due" | "dw" | "interest"|"bo",
    data: { date: any; rest: any; index: number }
  ) => {
    const { date, rest, index } = data;
    type == "due" && rest.setFieldValue(`transations[${index}].tDueDate`, date);
    type == "dw" && rest.setFieldValue(`transations[${index}].tDWDate`, date);
    type == "interest" &&
      rest.setFieldValue(`transations[${index}].tInterestDate`, date);
      type == "bo" &&
      rest.setFieldValue(`transations[${index}].tBODate`, date);
  };

  

  return (
    <FieldArray
      name="transations"
      render={({ remove, push }) => (
        <>
          {values.transations?.map((transaction: any, index: number) => (
            <div className="trans-item" key={index}>
              <$Popconfirm
                title={t(
                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DELETECONFIRM"
                )}
                onConfirm={() => remove(index)}
                okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                placement="left"
              >
                {index !== 0 && (
                  <$Tooltip placement="topRight" title={t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DELETE_TRANSACTION")}>
                    <$Button
                      className="trans-item-remove"
                      danger
                      type="link"
                      size="small"
                      icon={<CloseOutlined style={{ fontSize: '18px'}} />}
                    />
                  </$Tooltip>
                )}
              </$Popconfirm>
              <$Row gutter={16}>
                <$Col
                  xxl={{ span: 12, offset: 0 }}
                  xl={{ span: 14, offset: 0 }}
                >
                  <$Row gutter={16}>
                    <$Col span={12}>
                      {!disabled && (
                        <a
                          onClick={() => openTransactionType(index)}
                          className="d-block"
                          style={{ marginBottom: "3px" }}
                        >
                          <span className="required">*</span>
                          <strong>
                            {t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TRANSACTION_TYPE"
                            )}
                          </strong>
                        </a>
                      )}
                      {disabled && (
                        <div style={{ marginBottom: "3px" }}>
                          <span className="required">*</span>
                          <strong>
                            {t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TRANSACTION_TYPE"
                            )}
                          </strong>
                        </div>
                      )}
                      <div style={{ marginBottom: "13px" }}>
                        <$Select
                          formitem={{}}
                          name={`transations[${index}].tType`}
                          size="small"
                          allOption={false}
                          style={{ width: "100%" }}
                          options={getTransactionType(transactionTypes,transations,index, isCaseRegistration)}
                          onSearchBy={["label"]}
                          disabled={disabled}
                        />
                      </div>
                    </$Col>
                    <$Col span={12}>
                      <$Input
                        name={`transations[${index}].tNumber`}
                        size="small"
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.REF_NO"
                        )}
                        required
                        disabled={disabled}
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16} className="mb-2">
                    <$Col span={12}>
                      <$InputAmount
                        name={`transations[${index}].tAmount`}
                        size="small"
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.AMOUNT"
                        )}
                        required
                        value={transaction.tAmount}
                        disabled={disabled}
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        className="w-100"
                        placeholder={IntlCurrency(
                          0,
                          currentLanguage,
                          currentCurrency
                        ).trim()}
                        min={0}
                      />
                    </$Col>
                    <$Col span={12}>
                      <$Input
                        name={`transations[${index}].tKid`}
                        size="small"
                        disabled={disabled}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TRANS_KID"
                        )}
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16}>
                    <$Col span={12}>
                      <$Input
                        name={`transations[${index}].tInfo`}
                        size="small"
                        disabled={disabled}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TRANS_TEXT"
                        )}
                      />
                    </$Col>
                  </$Row>
                </$Col>
                <$Col
                  xxl={{ span: 11, offset: 1 }}
                  xl={{ span: 10, offset: 0 }}
                >
                  <$Row gutter={16}>
                    <$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      className="mb-2"
                    >
                      <$DatePicker
                        allowClear={true}
                        name={`transations[${index}].tDate`}
                        placeholder={t(
                          "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                        )}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.VOUCHER_DATE"
                        )}
                        disabled={disabled}
                        format={currentDateFormat}
                        className="w-100"
                        size="small"
                        onChange={(e: any) =>
                          onChangeVoucherDate(e, rest, index)
                        }
                        value={
                          moment(values.transations[index].tDate).isValid()
                            ? moment(values.transations[index].tDate)
                            : null
                        }
                      />
                    </$Col>
                    <$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      className="mb-2"
                    >
                      <$DatePicker
                        allowClear={true}
                        name={`transations[${index}].tDueDate`}
                        placeholder={t(
                          "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                        )}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DUE_DATE"
                        )}
                        format={currentDateFormat}
                        disabled={disabled}
                        className="w-100"
                        size="small"
                        onChange={(date: any) =>
                          onDateChange("due", { date, rest, index })
                        }
                        value={
                          moment(values.transations[index].tDueDate).isValid()
                            ? moment(values.transations[index].tDueDate)
                            : null
                        }
                      />
                    </$Col>
                    
                    <$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      className="mb-2"
                    >
                      <$DatePicker
                        allowClear={true}
                        name={`transations[${index}].tDWDate`}
                        placeholder={t(
                          "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                        )}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DW_DATE"
                        )}
                        format={currentDateFormat}
                        disabled={disabled}
                        style={{ width: "100%" }}
                        size="small"
                        value={
                          moment(values.transations[index].tDWDate).isValid()
                            ? moment(values.transations[index].tDWDate)
                            : null
                        }
                        onChange={(date: any) =>
                          onDateChange("dw", { date, rest, index })
                        }
                      />
                    </$Col>
                    {isEnabledBODate(transations,index,isCaseRegistration) &&<$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      className="mb-2"
                    >
                      <$DatePicker
                        name={`transations[${index}].tBODate`}
                        placeholder={t(
                          "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                        )}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.BO_DATE"
                        )}
                        allowClear={true}
                        format={currentDateFormat}
                        disabled={disabled}
                        style={{ width: "100%" }}
                        size="small"
                        onChange={(date: any) =>
                          onDateChange("bo", { date, rest, index })
                        }
                        value={
                          moment(
                            values.transations[index].tBODate
                          ).isValid()
                            ? moment(values.transations[index].tBODate)
                            : null
                        }
                      />
                    </$Col>}
                    
                    <$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                    >
                      <$DatePicker
                        name={`transations[${index}].tInterestDate`}
                        placeholder={t(
                          "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                        )}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.INTEREST_FROM_DATE"
                        )}
                        allowClear={true}
                        format={currentDateFormat}
                        disabled={disabled}
                        style={{ width: "100%" }}
                        size="small"
                        onChange={(date: any) =>
                          onDateChange("interest", { date, rest, index })
                        }
                        value={
                          moment(
                            values.transations[index].tInterestDate
                          ).isValid()
                            ? moment(values.transations[index].tInterestDate)
                            : null
                        }
                      />
                    </$Col>
                    <$Col
                      span={12}
                      xxl={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      className="interest-rate"
                    >
                      <$Input
                        name={`transations[${index}].tInterestRate`}
                        size="small"
                        disabled={disabled}
                        addonAfter={"%"}
                        label={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.INTEREST_RATE"
                        )}
                        formitem={{extra:t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.APPLICABLE_ONLY_FOR_FIXED_INTEREST_RATE")}}
                      />
                    </$Col>
                  </$Row>
                </$Col>
              </$Row>
            </div>
          ))}
          {typeof error === "string" && (
            <div className="text-error">{t(error)}</div>
          )}
          <$Divider orientation="left" className="ti-divider">
            <$Button
              type="dashed"
              size="small"
              onClick={() => push(initials)}
              disabled={disabled}
              icon={<PlusOutlined />}
            >
              {t(
                "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.ADD_NEW_TRANSACTION"
              )}
            </$Button>
          </$Divider>
        </>
      )}
    />
  );
};
export default TransactionSetUp;
