import { IInitialState } from "../Interfaces";
import { initailEntity } from "./Entity";

export const initialState: IInitialState = {
  creditors: {
    data: [],
    isLoading: false,
  },
  creditor: {
    data: initailEntity,
    isLoading: false,
  },
  formValues: {},
  isValidCreditor: true,
  debtors: {
    data: [],
    isLoading: false,
  },
  debtor: {
    data: initailEntity,
    isLoading: false,
  },
  workflows: {
    data: [],
    isLoading: false,
  },
  registeredCase: {
    data: [],
    isLoading: false,
  },
  postalcode: {
    data: [],
    isLoading: false,
  },
  municipility: {
    data: [],
    isLoading: false,
  },
};
