import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

export default {
  atgs: {
    search: <P extends {}>(params: P): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.get(
            "arservice",
            "atg",
            params,
            ServiceConfig()[`arservice`]["atg.search"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  atg: {
    download: (params: string): Promise<{}> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data } = await versionedHttpCollection.getFile(
            "arservice",
            "atg-download",
            {fileName: params},
            ServiceConfig()[`arservice`]["atg.download"]
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
