import * as Yup from 'yup';

export default () => {
	return Yup.object().shape({
		mainAmount: Yup.string().test(
			'matchMain',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (mainAmount) {
				mainAmount =
					typeof mainAmount == 'undefined' ? '0.00' : mainAmount;
				let res: boolean = true;
				if (mainAmount) {
					res =
						parseFloat(mainAmount?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.mainBalance
								.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		collectionFee: Yup.string().test(
			'matchCollection',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (collectionFee) {
				collectionFee =
					typeof collectionFee == 'undefined'
						? '0.00'
						: collectionFee;
				let res: boolean = true;
				if (collectionFee) {
					res =
						parseFloat(collectionFee?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.collectionBalance
								.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		courtFee: Yup.string().test(
			'matchCourtFee',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (courtFee) {
				courtFee = typeof courtFee == 'undefined' ? '0.00' : courtFee;
				let res: boolean = true;
				if (courtFee) {
					res =
						parseFloat(courtFee?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.courtBalance
								.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		costFee: Yup.string().test(
			'matchcostFee',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (costFee) {
				costFee = typeof costFee == 'undefined' ? '0.00' : costFee;
				let res: boolean = true;
				if (costFee) {
					res =
						parseFloat(costFee?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.costBalance
								.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		otherFee: Yup.string().test(
			'matchotherFee',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (otherFee) {
				otherFee = typeof otherFee == 'undefined' ? '0.00' : otherFee;
				let res: boolean = true;
				if (otherFee) {
					res =
						parseFloat(otherFee?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.otherBalance
								.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		interestMainAmount: Yup.string().test(
			'matchInterestMainAmount',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (interestMainAmount) {
				interestMainAmount =
					typeof interestMainAmount == 'undefined'
						? '0.00'
						: interestMainAmount;

				let res: boolean = true;
				if (interestMainAmount) {
					res =
						parseFloat(interestMainAmount?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.interestMainBalance
								?.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),

		interestCostAmount: Yup.string().test(
			'matchInterestCostAmount',
			'US.COLLECTION.VALIDATIONS:INVALID.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
			function (interestCostAmount) {
				interestCostAmount =
					typeof interestCostAmount == 'undefined'
						? '0.00'
						: interestCostAmount;
				let res: boolean = true;
				if (interestCostAmount) {
					res =
						parseFloat(interestCostAmount?.replaceAll(/\D/g, '')) <=
						parseFloat(
							this.parent.interestCostBalance
								?.toString()
								.replaceAll(/\D/g, '')
						);
				}
				return res;
			}
		),
		totalAmount: Yup.mixed().test('totalValue', '', function (totalAmount) {
			const { path, parent, createError } = this;
			if (totalAmount) {
				if (
					parseFloat(totalAmount?.replaceAll(/\D/g, '')) <
					parseFloat(
						parent.minCaseBalance.toString().replaceAll(/\D/g, '')
					)
				) {
					let a = parseFloat(totalAmount?.replaceAll(/\D/g, ''));
					let b = parseFloat(
						parent.minCaseBalance.toString().replaceAll(/\D/g, '')
					);
					console.log(a, b);
					return createError({
						path,
						message: `US.COLLECTION.VALIDATIONS:NOTIFICATION.AGREEMENT_BALANCE_SHOULD_BE_GREATER_THAN
						${parseFloat(parent.minCaseBalance)}`,
					});
				}
			}
			return true;
		}),
	});
};
