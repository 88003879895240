export enum ATG {
  SEARCH_ATG = "SEARCH_ATG",
  SEARCH_ATG_SUCCESS = "SEARCH_ATG_SUCCESS",
  SEARCH_ATG_FAIL = "SEARCH_ATG_FAIL",

  GET_ATG = 'GET_ATG',
  GET_ATG_SUCCESS = 'GET_ATG_SUCCESS',
  GET_ATG_FAIL = 'GET_ATG_FAIL',

  GET_MASTER_CREDITOR = 'GET_MASTER_CREDITOR',
  GET_MASTER_CREDITOR_SUCCESS = 'GET_MASTER_CREDITOR_SUCCESS',
  GET_MASTER_CREDITOR_FAIL = 'GET_MASTER_CREDITOR_FAIL'
}
