import React from 'react'
import { ToggleClass } from 'us.helper';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IO from 'us.collection.input-output';

interface ICnt_RegisterInvoice extends RouteComponentProps {
}

const Cnt_RegisterInvoice: React.FC<ICnt_RegisterInvoice> = (props) => {
    ToggleClass('change-body-color-dom');

    return (
        <IO.Components.RegisterInvoice.Home {...props}  />
    )
}
export default withRouter(Cnt_RegisterInvoice);