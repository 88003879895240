import { IAPIAction } from "us.collection/interfaces";
import { Creditor } from "us.collection.input-output/constants/Actions";

export const creditor: Readonly<IAPIAction> = {
    creditors: {
        get: (data) => ({
            type: Creditor.GET_CREDITORS_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Creditor.GET_CREDITORS_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Creditor.GET_CREDITORS_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    self: {
        get: (data) => ({
            type: Creditor.GET_CREDITOR_BY_NUMBER_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Creditor.GET_CREDITORS_BY_NUMBER_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Creditor.GET_CREDITORS_BY_NUMBER_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        set: (data) => ({
            type: Creditor.SET_CREDITOR_STATUS,
            payload: {
                data,
                isLoading: true
            }
        }),

    }
}