import React, { useState, memo, useEffect } from "react";
import { Formik, useField } from "formik";
import { connect } from "react-redux";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import {
  PlusOutlined,
  MoreOutlined,
  BoxIcons,
  IconTypes,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  SearchOutlined,
  FilterOutlined,
  DownloadOutlined,
} from "us.icons";
import moment from "moment";
import { atg } from "us.collection.input-output/actions";
import { getCreditorByCompanyId } from "us.common/actions";
import { IRootState } from "us.collection/interfaces";
import { IntlDate, IntlCurrency } from "us.common/functions";
// import { creditor } from "us.collection.creditor/reducers";

export interface ISortOrder {
  columnKey: string;
  order: any;
}

export interface IATGHome {
  searchATG?: any;
  downloadATG?: any;
  atgs?: Array<any>;
  isFetching: boolean;
  getMasterCreditors?: any;
  getCreditorByCompanyId?: any;
  creditorCompanies?: Array<any>;
  creditors?: Array<any>;
  currentDateFormat: string;
  currentLanguage: string;
  currentCurrency: string;
}

const {
  $Input,
  $Select,
  $RangePicker,
  $Popconfirm,
  $Button,
  $Divider,
  $Tooltip,
  $PageHeader,
  $Popover,
  $Affix,
  $Search,
  $Drawer,
  $Skeleton,
  $Tag,
  $Table,
} = Common.Components;

const ATGHome: React.FC<IATGHome> = memo((props) => {
  const { t } = useTranslation("");

  const {
    searchATG,
    downloadATG,
    atgs,
    getMasterCreditors,
    getCreditorByCompanyId,
    creditorCompanies,
    creditors,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    isFetching,
  } = props;

  const [sortedInfo, setSortedInfo] = useState<ISortOrder>({
    columnKey: "",
    order: "",
  });

  const [creditorsTable, setCreditors] = useState<any>(
    creditors ? creditors : []
  );

  // const [durationTime, , durationTimeHelpers] = useField(`durationTime`);

  const [mainFilters, setMainFilters] = useState<any>({
    invoiceKID: "",
    fromDate: moment().subtract(3, "months").format("YYYY/MM/DD"),
    toDate: moment().format("YYYY/MM/DD"),
    creditor: "",
    creditorCompany: "",
  });

  useEffect(() => {
    getMasterCreditors();
  }, []);

  useEffect(() => {
    searchATG(mainFilters);
  }, [searchATG]);

  useEffect(() => {
    setCreditors(creditors);
  }, [creditors]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const downloadATGHandler = (record: any) => {
    downloadATG(record?.fileName);
  };

  // Popover actions in table
  const content = (record: any) => (
    <div className="table-more-content">
      <div
        className="d-flex mb-2 flex-row more-item"
        onClick={() => downloadATGHandler(record)}
      >
        <div className="p-1">
          <DownloadOutlined />
        </div>
        <div className="p-1">Download ATG File</div>
      </div>
      <div className="d-flex mb-2 flex-row more-item">
        <div className="p-1">
          <BoxIcons type={IconTypes.BOX_ICON} name="Proceed-Cancelation" />
        </div>
        <div className="p-1">Proceed to Cancelation</div>
      </div>
      <div className="d-flex mb-2 flex-row more-item">
        <div className="p-1">
          <DownloadOutlined />
        </div>
        <div className="p-1">Download Cancelation File</div>
      </div>
    </div>
  );
  const columns: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any) => {
        return (
          <$Popover
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
          >
            <$Button icon={<MoreOutlined />} size="small" />
          </$Popover>
        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "generatedDateTime",
      key: "generatedDateTime",
      render: (text: any, record: any, index: number) => {
        return (
          <span>
            {IntlDate(
              text,
              currentLanguage,
              Common.Constants.dateParams.DATE_TIME24
            )}
          </span>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: any, record: any) => {
        return (
          <div>
            {text == null && (
              <$Tag color="blue" className="" style={{ textAlign: "center" }}>
                READY TO DOWNLOAD
              </$Tag>
            )}
            {text == "Pending" && (
              <$Tag color="yellow" className="" style={{ textAlign: "center" }}>
                PENDING APPROVAL
              </$Tag>
            )}
            {text == "Rejected" && (
              <$Tag color="red" className="" style={{ textAlign: "center" }}>
                REJECTED
              </$Tag>
            )}
            {text == "Approved" && (
              <$Tag color="green" className="" style={{ textAlign: "center" }}>
                APPROVED
              </$Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "ATG File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Total Records",
      dataIndex: "numberOfRecords",
      key: "numberOfRecords",
      width: "150px",
    },
    {
      title: "Total Amount",
      dataIndex: "totalRecordValue",
      key: "totalRecordValue",
      align: "right",
      width: "180px",
      render: (text: any, record: any) => {
        return (
          <span>{IntlCurrency(text, currentLanguage, currentCurrency)}</span>
        );
      },
    },

    {
      title: "Last Downloaded",
      key: "lastDownloadedDate",
      dataIndex: "lastDownloadedDate",
      render: (text: any, record: any) => {
        return (
          <div className="d-flex flex-column" style={{ minHeight: "38px" }}>
            {IntlDate(text, currentLanguage, Common.Constants.dateParams.DATE)}
            <span className="text-muted font-sm">
              {record?.lastDownloadedBy && `by ${record?.lastDownloadedBy}`}
            </span>
          </div>
        );
      },
    },
  ];

  const onSearchATGByInvoiceKid = (searchText: string) => {
    setMainFilters({ ...mainFilters, invoiceKID: searchText });
    searchATG({ ...mainFilters, invoiceKID: searchText });
  };

  const onChangeRange = (range: any, rest: any) => {
    try {
      const [startDate, endDate] = range;
      const fromDate = moment(startDate).format("YYYY/MM/DD");
      const toDate = moment(endDate).format("YYYY/MM/DD");
      // durationTimeHelpers.setValue(range)
      setMainFilters({ ...mainFilters, fromDate, toDate });
      searchATG({ ...mainFilters, fromDate, toDate });
      rest.setFieldValue("durationTime", range);
    } catch (error) {
      setMainFilters({ ...mainFilters, fromDate: "", toDate: "" });
      searchATG({ ...mainFilters, fromDate: "", toDate: "" });
      rest.setFieldValue("durationTime", range);
    }
  };
  const ATGFilterByType = async (type: any = "ATG") => {
    // Do this after add this key to ATG search API
  };

  const ATGFilterByMasterCreditor = async (masterCreditor: any = "") => {
    const keys = masterCreditor.split("_");
    setMainFilters({ ...mainFilters, creditorCompany: keys[0], creditor: "" });
    searchATG({ ...mainFilters, creditorCompany: keys[0], creditor: "" });

    getCreditorByCompanyId([masterCreditor]);
    setCreditors([]);
  };

  const ATGFilterByCreditor = async (creditor: any = "") => {
    const keys = creditor.split("_");
    setMainFilters({ ...mainFilters, creditor: keys[0] });
    searchATG({ ...mainFilters, creditor: keys[0] });
  };

  return (
    <Formik
      initialValues={{
        durationTime: [moment().subtract(3, "months"), moment()],
      }}
      onSubmit={({ values, action }: any) => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <div className="space-content">
          <$Affix offsetTop={48}>
            <div className="page-header header-border">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    onBack={() => null}
                    title="Manage ATG"
                    className="px-0"
                  />
                  <$Divider className="bui-devider" type="vertical" />

                  <$Search
                    formitem={{className: "boxform-horizontal"}}
                    name="search"
                    size="small"
                    className="mr-3"
                    allowClear
                    placeholder="Invoice No/KID"
                    style={{ width: 180 }}
                    onSearch={(value: string) => onSearchATGByInvoiceKid(value)}
                    onPressEnter={(e: any) =>
                      onSearchATGByInvoiceKid(e.target.value)
                    }
                  />
                  <$RangePicker
                    name="durationTime"
                    placeholder={["Start Date", "End Date"]}
                    currentTimeFormat={currentDateFormat}
                    size="small"
                    onChange={(range: any) => onChangeRange(range, rest)}
                  />
                </div>
                <div className="ml-5 d-flex">
                  <$Select
                    formitem={{className: "boxform-horizontal"}}
                    name="atgSummary"
                    size="small"
                    style={{ width: 180 }}
                    className="mr-3"
                    options={[
                      {
                        label: "ATG Summary",
                        value: "ATG",
                      },
                      {
                        label: "Cancellation Summary",
                        value: "Cancellation",
                      },
                      {
                        label: "Receipts",
                        value: "Receipts",
                      },
                    ]}
                    onChange={ATGFilterByType}
                    allOption={false}
                    defaultValue={"ATG"}
                    optionValue="value"
                    optionText="label"
                  />
                  <$Select
                    formitem={{className: "boxform-horizontal"}}
                    name="atgCompany"
                    size="small"
                    style={{ width: 180 }}
                    className="mr-3"
                    options={
                      creditorCompanies
                        ? [{ value: "", label: "All" }, ...creditorCompanies.map((company: any) => { return {value: company?.value, label: `${company?.value?.split('_')[0]} ${company?.label}`}})]
                        : []
                    }
                    onSearchBy={["label"]}
                    onChange={ATGFilterByMasterCreditor}
                    allOption={false}
                    placeholder="Master Creditor"
                    optionValue="value"
                    optionText="label"
                    // dropdownMatchSelectWidth={false}
                  />
                  <$Select
                    formitem={{className: "boxform-horizontal"}}
                    name="atgGtym"
                    size="small"
                    style={{ width: 180 }}
                    options={[{ value: "", label: "All" }, ...creditorsTable.map((creditor: any) => { return {value: creditor?.value, label: `${creditor?.value?.split('_')[0]} ${creditor?.label}`}})]}
                    onSearchBy={["label"]}
                    onChange={ATGFilterByCreditor}
                    allOption={false}
                    placeholder="Creditor"
                    optionValue="value"
                    optionText="label"
                  />
                </div>
              </div>
            </div>
          </$Affix>
          <$Skeleton loading={isFetching} active paragraph={{ rows: 5 }}>
            <$Table
              onChange={handleTableChange}
              className="mt-3"
              bordered
              size="small"
              dataSource={atgs}
              rowKey="fileName"
              columns={columns}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 15 }}
            />
          </$Skeleton>
        </div>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { atg, common, user } = state;
  const { atgs } = atg;
  const { creditorCompanies, creditors } = user;

  return {
    atgs: atgs.data,
    isFetching: atgs.isFetching,
    currentDateFormat: common.currentDateFormat,
    currentLanguage: common.currentLanguage,
    currentCurrency: common.currentCurrency,
    creditorCompanies,
    creditors,
  };
};

const mapDispatchToProps = {
  searchATG: atg.atgs.search,
  downloadATG: atg.atg.get,
  getMasterCreditors: atg.masterCreditors.get,
  getCreditorByCompanyId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ATGHome);
