import React from "react";
import InputOutput from "us.collection.input-output";
import { Route } from "react-router-dom";
// import { DomainView, CaseView } from "../layouts";
import {
  MainSearch,
  UserRoleSettings,
  UserSettings,
  Home
} from "./asyncRoutes";

export default (caseNo: number) => {
  const MainViewModules = [InputOutput];

  return [
    <Route
      exact
      key="search"
      path={"/search"}
      render={(props) => <MainSearch {...props} />}
    />,
    <Route
      exact
      key="/"
      path={"/"}
      render={() => <Home />}
    />,
    ...MainViewModules.map(({ Routes }) => Routes.asyncRoutes),
  //   <Route
  //   key="/:caseType/:caseId"
  //   path="/:caseType/:caseId"
  //   render={({ match: { url } }) => (
  //     <DomainView isFollowup="true">
  //       <CaseView.Layout>
  //         {CaseViewModules.map(({ Routes }, index) => (
  //           <React.Fragment key={url + index}>
  //             {asyncRoutes(url, caseNo, CaseType.C, Routes.Containers)}
  //           </React.Fragment>
  //         ))}
  //       </CaseView.Layout>
  //     </DomainView>
  //   )}
  // />,
  ];
};
