import { Formik } from "formik";
import React, { useEffect, useState, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { IRootState } from 'us.collection/interfaces'
import * as Actions from "us.collection.input-output/actions";
import {ICreditorTable}from "us.collection.input-output/components/RegisterInvoice/Interfaces"
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const { creditor } = Actions

const {
    $Skeleton,
    $TableTree
} = Common.Components

const CrediorTable: React.FC<ICreditorTable> = memo((props) => {
    const { t } = useTranslation(['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'], { useSuspense: true });
    const {
        getCreditors,
        creditors,
        selectCreditor,
        onClose
    } = props;

    useEffect(() => {
        getCreditors({
            roleType: 'CRE',
            searchCriteria: 'Status',
            keyword: 'Active'
        })
    }, [])

    const handleSubmit = ((data: any, actions: any) => {
    })

    const columns: any = [
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CREDITOR_NO"),
            dataIndex: "creditorNo",
            key: "creditorNo",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a - b,
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CREDITOR_NAME"),
            dataIndex: "fullName",
            key: "fullName",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
    ]

    const handleSort: IOnSort = (sortData, dataSource) => {
        return sortData(dataSource);
      };
    
      const handleFilter: IOnFilter = (searchData, dataSource) => {
        return searchData(dataSource);
      };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
                resetForm,
                ...restProps
            }: any) => (
                <>
                    <$Skeleton
                        loading={creditors?.isLoading}
                        active
                        paragraph={{ rows: 2 }}
                    >

                    <$TableTree
                        rowKey="id"
                        data={creditors?.data}
                        size="small"
                        className=""
                        onSort={handleSort}
                        onFilter={handleFilter}
                        filterOnType={true}
                        resetOnSourceChange={true}
                        bordered
                        pagination={{ defaultPageSize: 15 }}
                        scroll={{ x: 400 }}
                        columns={columns}
                        onRow={(record: any, rowIndex: any) => {
                            return {
                                onDoubleClick: (event: any) => {
                                    selectCreditor(record);
                                    onClose()
                                },
                            };
                        }}
                        />
                    </$Skeleton>
                </>
            )}
        </Formik>
    );
});

const mapStateToProps = (state: IRootState) => {
    const { invoice } = state
    const { creditors } = invoice
    return {
        creditors
    };
};

const mapDispatchToProps = {
    getCreditors: creditor.creditors.get
};

export default connect(mapStateToProps, mapDispatchToProps)(CrediorTable);