import { Formik } from "formik";
import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { IRootState } from 'us.collection/interfaces'
import * as Actions from "us.collection.input-output/actions";
import {IDebtorTable}from 'us.collection.input-output/components/RegisterInvoice/Interfaces'
import { IOnFilter, IOnSort } from "us.common/components/antd/TableTree";

const { debtor } = Actions

const {
    $Skeleton,
    $TableTree
} = Common.Components

const DebtorTable: React.FC<IDebtorTable> = memo((props) => {
    const { t } = useTranslation(['US.COLLECTION.CASE', 'US.COLLECTION.COMMON'], { useSuspense: true });

    const {
        debtors,
        getDebtors,
        creditorInkassoId,
        selectDebtor,
        onClose
    } = props;



    useEffect(() => {
        getDebtors({
            roleType: 'DEB',
            searchCriteria: 'CreditorNumber',
            keyword: creditorInkassoId
        })
    }, [])

    const handleSubmit = ((data: any, { setErrors, setStatus, resetForm, isSubmitting, isValidating, setSubmitting }: any) => {
    })

    const columns: any = [
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DEBTOR_NO"),
            dataIndex: "cid",
            key: "cid",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a - b,
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.FIRST_NAME"),
            dataIndex: "firstName",
            key: "firstName",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.LAST_NAME"),
            dataIndex: "lastName",
            key: "lastName",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.POSTAL_CODE"),
            dataIndex: "zipCode",
            key: "zipCode",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.POSTAL_NAME"),
            dataIndex: "zipName",
            key: "zipName",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DEBTOR_TYPE"),
            dataIndex: "debtorType",
            key: "debtorType",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
        {
            title: t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.NIN"),
            dataIndex: "nIN",
            key: "nIN",
            className: "text-nowrap",
            customSorter: (a: any, b: any) => a.localeCompare(b),
            customFilter: true,
        },
    ]

    const handleSort: IOnSort = (sortData, dataSource) => {
        return sortData(dataSource);
      };
    
      const handleFilter: IOnFilter = (searchData, dataSource) => {
        return searchData(dataSource);
      };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
                resetForm,
                ...restProps
            }: any) => (
                <>
                    <$Skeleton
                        loading={debtors?.isLoading}
                        active
                        paragraph={{ rows: 2 }}
                    >

                        <$TableTree
                            rowKey="id"
                            data={debtors?.data}
                            size="small"
                            className=""
                            onSort={handleSort}
                            onFilter={handleFilter}
                            filterOnType={true}
                            resetOnSourceChange={true}
                            bordered
                            pagination={{ defaultPageSize: 15 }}
                            scroll={{ x: 400 }}
                            columns={columns}
                            onRow={(record: any, rowIndex: any) => {
                                return {
                                    onDoubleClick: (event: any) => {
                                        selectDebtor(record)
                                        onClose()
                                    },
                                };
                            }}
                        />
                    </$Skeleton>
                </>
            )}
        </Formik>
    );
});

const mapStateToProps = (state: IRootState) => {
    const { invoice } = state
    const { debtors } = invoice
    return {
        debtors
    };
};

const mapDispatchToProps = {
    getDebtors: debtor.debtors.get
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtorTable);