import IO from 'us.collection.input-output/sagas';
import { PaymentAgreementSagas } from './PaymentAgreement'
import { ObsoleteDateSagas } from './ObsoleteDate'
import {TransTypesSagas} from './Transactions'

export default [
  ...IO,
  ...PaymentAgreementSagas,
  ...ObsoleteDateSagas,
  ...TransTypesSagas
]