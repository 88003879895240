import { IFormData } from "../Interfaces"
export const initialFromData: IFormData = {
    firstName: '',
    lastName: '',
    nIN: '',
    cid: '',
    debtorType: '',
    birthDate: '',
    address1: '',
    address2: '',
    address3: '',
    zipCode: '',
    zipName: '',
    municipalityCode: '',
    municipalityName: '',
    country: 'NO',
    sms: '',
    tel: '',
    email: ''
}

export const dateFormats = {
    req: 'YYYY-MM-DD'
}