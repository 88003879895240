import * as Yup from 'yup'
import moment from 'moment'
import { validatePersonNo, refNoValidate } from 'us.collection.input-output/functions'
import { organizationNoValidate, personNoValidation } from 'us.collection/functions'
import { DEFAULT_COUNTRY_CODE } from 'us.collection/constants'

export default () => {
    return Yup.object().shape(
        {
            creditorNo: Yup.number()
                .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),

            cid: Yup.string()
                .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),

            lastName: Yup.string()
                .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
            country: Yup.string()
                .required('Please add a value into the field.'),
            zipCode: Yup.string()
                .when('country', {
                    is: (countryId) => countryId === DEFAULT_COUNTRY_CODE,
                    then: Yup.string()
                        .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')
                        .matches(/^\d+$/, 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'),
                }),
            zipName: Yup.string()
                .nullable()
                .typeError(
                    'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
                )
                .when(['zipCode'], {
                    is: (zipCode) => {
                        zipCode = typeof zipCode == 'undefined' ? '' : zipCode;
                        return zipCode?.toString().length !== 0;
                    },
                    then: Yup.string()
                        .required(
                            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'
                        )
                        .test(
                            'zipNameDigit',
                            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
                            (val: any) => {
                                let value =
                                    typeof val == 'undefined' ? '' : val;
                                return !/^\d+$/.test(value);
                            }
                        ),
                }),
            municipalityCode: Yup.number()
                .nullable().
                typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'),

            email: Yup.string()
                .email('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'),

            transations: Yup.array()
                .of(
                    Yup.object().shape({
                        tType: Yup.string()
                            .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
                        tNumber: Yup.string()
                            .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
                        tInterestRate: Yup.number()
                            .max(100, 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD'),
                        tKid: Yup.string()
                            .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                            .nullable().test('tKid', 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD', (val: any) => {
                                const value = typeof val == "undefined" ? "" : val
                                return !isNaN(value) || value == ""
                            }),
                        tAmount: Yup.string()
                            .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                            .test(
                                'tAmount',
                                'US.COLLECTION.VALIDATIONS:INVALID.AMOUNT_SHOULD_BE_GREATER_THAN_ZERO',
                                (val: any) => {
                                    val = typeof val == "undefined" ? "0.00" : val;
                                    return Number(val?.toString()) > 0
                                }

                            ).required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
                        tDate: Yup.date()
                            .nullable().
                            required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),

                        tDueDate: Yup.date()
                            .when(
                                'tDate',
                                (tDate: any, Yup: any) => {
                                    let result: any
                                    if (typeof tDate === "undefined" || tDate === null) {
                                        result = Yup.nullable();
                                    } else {
                                        result = Yup.min(moment(tDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_VOUCHER_DATE")
                                    }
                                    return result
                                }

                            ).nullable().required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD'),
                        tDWDate: Yup.date()
                            .when(
                                ['tDate', 'tDueDate', 'tDWDate'],
                                (tDate: any, tDueDate: any, tDWDate: any, Yup: any) => {
                                    let result: any;
                                    if (typeof tDate === "undefined" || tDate === null) {
                                        if (typeof tDueDate === "undefined" || tDueDate === null) {
                                            result = Yup.nullable();
                                        } else {
                                            result = Yup.min(moment(tDueDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_DUE_DATE")
                                        }
                                    } else {
                                        if (typeof tDueDate === "undefined" || tDueDate === null) {
                                            result = Yup.min(moment(tDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_VOUCHER_DATE")
                                        } else {
                                            if (typeof tDWDate === "undefined" || tDWDate === null) {
                                                result = Yup.nullable();
                                            } else {
                                                if (moment(tDWDate).isBefore(tDate)) {
                                                    result = Yup.min(moment(tDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_VOUCHER_DATE")
                                                } else if (moment(tDWDate).isBefore(tDueDate)) {
                                                    result = Yup.min(moment(tDueDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_DUE_DATE")
                                                } else {
                                                    result = Yup.nullable();
                                                }

                                            }
                                        }
                                    }
                                    return result;
                                }

                            ).when(['tType', 'tBODate'], (tType: any, tBODate: any, Yup: any) => {
                                let result: any
                                if (tType == 3) {
                                    if (typeof tBODate === "undefined" || tBODate === null) {
                                        result = Yup.nullable();
                                    } else {
                                        result = Yup.required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD')
                                    }
                                } else {
                                    result = Yup.nullable();
                                }
                                return result;
                            }).nullable(),
                        tInterestDate: Yup.date()
                            .when(
                                ['tDate', 'tDueDate', 'tInterestDate'],
                                (tDate: any, tDueDate: any, tInterestDate: any, Yup: any) => {
                                    let result: any;
                                    if (typeof tDate === "undefined" || tDate === null) {
                                        if (typeof tDueDate === "undefined" || tDueDate === null) {
                                            result = Yup.nullable();
                                        } else {
                                            result = Yup.min(moment(tDueDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_DUE_DATE")
                                        }
                                    } else {
                                        if (typeof tDueDate === "undefined" || tDueDate === null) {
                                            result = Yup.min(moment(tDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_VOUCHER_DATE")
                                        } else {
                                            if (typeof tInterestDate === "undefined" || tInterestDate === null) {
                                                result = Yup.nullable();
                                            } else {
                                                if (moment(tInterestDate).isBefore(tDate)) {
                                                    result = Yup.min(moment(tDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_VOUCHER_DATE")
                                                } else if (moment(tInterestDate).isBefore(tDueDate)) {
                                                    result = Yup.min(moment(tDueDate), "US.COLLECTION.VALIDATIONS:INVALID.VALUE_SHALL_BE_GREATER_THAN_THE_DUE_DATE")
                                                } else {
                                                    result = Yup.nullable();
                                                }

                                            }
                                        }
                                    }
                                    return result;
                                }

                            ).nullable()
                    }, [['tDWDate', 'tDWDate'], ['tInterestDate', 'tInterestDate']])
                ).when('registerAs', {
                    is: (val: any) => val === "Invoice",
                    then: Yup.array().when('transations', {
                        is: (val: any) => val?.filter((o: any) => o.tType?.toString() === '3')?.length > 1,
                        then: Yup.array().test({
                            message: 'US.COLLECTION.VALIDATIONS:INVALID.ADDING_ANOTHER_IN_TYPE_TRANSACTION_IS_NOT_POSSIBLE',
                            test: (o: any) => false,
                        }),
                        otherwise: Yup.array()
                            .test({
                                message: 'US.COLLECTION.VALIDATIONS:INVALID.ADDING_ONE_IN_TRANSACTION_IS_REQUIRED',
                                test: (arr: any) => {
                                    return arr?.filter((o: any) => o.tType?.toString() === '3')?.length != 0
                                }
                            }),
                    }),
                    otherwise: Yup.array()
                        .test({
                            message: 'US.COLLECTION.VALIDATIONS:INVALID.ADDING_ONE_IN_TRANSACTION_IS_REQUIRED',
                            test: (arr: any) => {
                                return arr?.filter((o: any) => o.tType?.toString() === '3')?.length != 0
                            }
                        })
                }).test('checkRFType', 'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_THE_REFERENCE_NUMBER_OF_IN', function () {
                    return refNoValidate(this.parent.transations)
                }),
            nIN: Yup.string()
                .when('debtorType', {
                    is: (val) => val === 'Company',
                    then: Yup.string()
                        .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                        .test(
                            'organizationNo',
                            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
                            (val: any) => {
                                val = typeof val == "undefined" ? "" : val.toString();
                                return organizationNoValidate(val)
                            }
                        ),
                    otherwise: Yup.string()
                        .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                        .test(
                            "personNo",
                            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
                            (value: any) => {
                                let isValidDate = true;
                                let val = value?.toString().replaceAll(/\D/g, '');
                                if (val) {
                                    if (isNaN(val)) {
                                        isValidDate = false;
                                    } else {
                                        isValidDate = validatePersonNo(val).isValid
                                    }
                                } else {
                                    val = ""
                                }
                                return isValidDate || val?.toString() === "";
                            }
                        ).test(
                            'personNo',
                            'US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD',
                            (val: any) => {
                                val = typeof val == "undefined" ? "" : val.toString();
                                return personNoValidation(val)
                            }
                        )
                }),
            sms: Yup.string()
                .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
                .nullable(),
            tel: Yup.string()
                .typeError('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALID_VALUE_INTO_THE_FIELD')
                .matches(/^\d{4,15}$/g, 'US.COLLECTION.VALIDATIONS:REQUIRED.THE_NUMBER_SHOULD_CONTAIN_BETWEEN_4_AND_15_DIGITS') 
                .nullable(),
            targetDate: Yup.date()
                .transform((curr, orig) => orig === null ? null : curr)
                .nullable()
                .when('startWorkflowAt', {
                    is: (val: any) => val?.toString()?.length > 0,
                    then: Yup.date().transform((curr, orig) => orig === null ? null : curr)
                        .required('US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ADD_A_VALUE_INTO_THE_FIELD').nullable(),
                    otherwise: Yup.date().transform((curr, orig) => orig === null ? null : curr).nullable()
                })

        }
    )
}


