import { IOption } from "us.collection.input-output/components/RegisterInvoice/Interfaces"

/**
 * @function
 * @description get transaction types for dropdown
 * @param {Array<IOption>} transactionTypes - transaction types which is gotten form API
 * @param {any} transactions - transaction data
 * @param {number} index - index of transaction array
 * @param {boolean} isCase true if Case registration,otherwise false
 * @returns 
 */
export const getTransactionType = (transactionTypes: Array<IOption>, transactions: any, index: number, isCase: boolean): Array<IOption> => {
    try {
        if (isCase) {
            return transactionTypes
        } else {
            if (transactions.findIndex(({ tType }: any) => tType == 3) == -1 || transactions.findIndex(({ tType }: any) => tType == 3) === index) {
                return transactionTypes
            }
            else {
                return transactionTypes.filter((item: IOption) => item.value != 3)
            }
        }
    } catch (error) {
        return transactionTypes
    }
}
/**
   * @function
   * @description Check BO date picker enable
   * @param {any} transactions - transaction data
   * @param {number} index field array index
   * @param {boolean} isCase true if Case registration,otherwise false
   * @returns true - if case registeration and first IN type
   */
export const isEnabledBODate = (transactions: any, index: number, isCase: boolean): boolean => {
    try {
        return isCase && transactions.findIndex(({ tType }: any) => tType == 3) === index
    } catch (error) {
        return false
    }
}