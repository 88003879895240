import { put, call, takeLatest } from "redux-saga/effects";
import * as API from "us.collection/services";
import * as Actions from "us.collection/actions";
import {
  TransType
} from "us.collection/constants";
import _ from 'lodash'

const { transactionTypes } = Actions;

const TransactionSagas = {

  /**
   * Get Transaction type
   * @param action
   */
  getTransactionTypes: function* (action: any): any {
    const params = action.payload;
    try {
      const data = yield call(API.default.TransactionTypes.getTransactionType, params);
      if (_.isEmpty(data) || data?.isError || data?.statusCode === 404) {
        yield put(transactionTypes.getFail(data));
      } else {
        yield put(transactionTypes.getSuccess(data));
}
    } catch (error) {
  yield put(transactionTypes.getFail(error));
  console.error(error);
}
  },
};

export default [
  takeLatest(
    TransType.GET_TRANS_TYPE_STRAT,
    TransactionSagas.getTransactionTypes
  )
];
