import { Formik } from "formik";
import React, { useEffect, useState, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import "./RegisterInvoice.scss";
import moment from "moment";
import { europeanCountry } from "us.common/constants";
import {
  CreditorTable,
  DebtorTable,
  Creditor,
  TransactionSetUp,
} from "./Components";
import PostalArea from "us.common/containers/PostalArea/PostalArea";
import * as Actions from "us.collection.input-output/actions";
import _ from "lodash";
import { ValidationSchema } from "us.collection.input-output/components/RegisterInvoice/Validation";
import { IEntity } from "us.collection.input-output/reducers/Invoice/Interfaces";
import { initailEntity } from "us.collection.input-output/reducers/Invoice/State";
import {
  validatePersonNo,
  mapDataFormData,
} from "us.collection.input-output/functions";
import { Guid } from "guid-typescript";
import * as Azure from "us.helper/azure";
import {
  ICountyValue,
  IRegisterInvoice,
  IFormData,
  ICreditorData,
} from "us.collection.input-output/components/RegisterInvoice/Interfaces";
import {
  initialFromData,
  dateFormats,
} from "us.collection.input-output/components/RegisterInvoice/Constants";
import { TransTypes } from "us.collection/components";
import * as ActionTrans from "us.collection/actions";
import { BoxIcons, IconTypes, ExclamationCircleFilled } from "us.icons";
import { appInsights, getRouteUrl } from "us.helper";
import { useHistory } from "react-router-dom";

const { creditor, debtor, invoice } = Actions;
const {
  $Form,
  $Affix,
  $PageHeader,
  $Divider,
  $Skeleton,
  $Button,
  $Row,
  $Col,
  $Radio,
  $Input,
  $Select,
  $DatePicker,
  $SelectGroup,
  $Drawer,
  $Popover,
  $AmountLabel,
  $DateLabel,
} = Common.Components;

const { validateNumber } = Common.Functions;

const RegisterInvoice: React.FC<IRegisterInvoice> = memo((props) => {
  const { t } = useTranslation();
  const [creditorData, setCreditorData] = useState<ICreditorData>({
    creditorNo: "",
    fullAddress: "",
    fullName: "",
  });
  const [debtorData, setdebtorData] = useState<IEntity>(initailEntity);
  const [popConfirmVisible, SetPopConfirmVisible] = useState<boolean>(false);
  const [country, setCountry] = useState<ICountyValue>({
    prevoius: "",
    current: "",
  });
  const [PostalAreaVisible, setPostalAreaVisible] = useState<boolean>(false);
  const [transactionTypeVisible, setTransactionTypeVisible] =
    useState<boolean>(false);
  const [transactionIndex, setTransactionIndex] = useState<number>(0);
  const [isExistingPostalCode, setIsExistingPostalCode] =
    useState<boolean>(true);
  const [isExistingMunicipalityCode, setIsExistingMunicipalityCode] =
    useState<boolean>(true);
  const [formData, setFormData] = useState<IFormData>(initialFromData);
  const [claimType, setClaimType] = useState<string>("Invoice");
  const initialCreditorRender = useRef<boolean>(true);
  const initialDebtorRender = useRef<boolean>(true);
  const initialPostalRender = useRef<boolean>(true);
  const initialMunicipilityRender = useRef<boolean>(true);
  const history=useHistory()
  const {goBack}=history;

  const {
    currentCurrency,
    currentLanguage,
    currentDateFormat,
    getCreditor,
    creditor,
    setStatus,
    debtor,
    getDebtor,
    isValidCreditor,
    drawerName,
    getTransactionTypes,
    transTypes,
    getWorkflowState,
    workflows,
    register,
    registeredCase,
    resetClaim,
    searchMunicipility,
    searchPostalcode,
    postalcode,
    municipility,
  } = props;

  const isAzureAD =  window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;
  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }
  const authentication = auth;
  const currentUser: any = authentication.currentUser();

  const initials = {
    tType: transTypes?.data?.length > 0 ? 3 : "",
    tNumber: "",
    tAmount: "",
    tKid: "",
    tInfo: "",
    tDate: moment().format(dateFormats.req),
    tDueDate: moment().add(14, "days").format(dateFormats.req),
    tDWDate: "",
    tInterestDate: "",
    tInterestRate: "",
    tBODate: "",
  };

  useEffect(() => {
    resetClaim({});
    setCreditorData({
      creditorNo: "",
      fullAddress: "",
      fullName: "",
    });
    getTransactionTypes("subcaseRegistration");
    getWorkflowState({ workflowEntity: "S" });
  }, []);

  useEffect(() => {
    if (initialCreditorRender.current) {
      initialCreditorRender.current = false;
    } else {
      setCreditorData(creditor?.data);
      setdebtorData(initailEntity);
      setIsExistingMunicipalityCode(true);
      setIsExistingPostalCode(true);
    }
  }, [creditor]);

  useEffect(() => {
    if (initialDebtorRender.current) {
      initialDebtorRender.current = false;
    } else {
      setdebtorData(debtor?.data);
      setIsExistingMunicipalityCode(true);
      setIsExistingPostalCode(true);
      setCountry({
        prevoius: "",
        current: debtor?.data?.countryCode,
      });
    }
  }, [debtor]);

  useEffect(() => {
    if (
      typeof creditorData?.creditorNo != "undefined" &&
      creditorData?.creditorNo?.length > 0
    ) {
      setdebtorData(mapDataFormData(formData));
    }
  }, [registeredCase.data]);

  useEffect(() => {
    if (initialPostalRender.current) {
      initialPostalRender.current = false;
    } else {
      if (postalcode?.data?.length > 0) {
        const { countyCode, countyName, postalPlace } = postalcode?.data[0];
        setdebtorData({
          ...mapDataFormData(formData),
          zipName: postalPlace,
          municipalityCode: countyCode,
          municipalityName: countyName,
        });
        setIsExistingPostalCode(true);
        setIsExistingMunicipalityCode(true);
      } else {
        setdebtorData({
          ...mapDataFormData(formData),
          zipName: "",
          municipalityCode: "",
          municipalityName: "",
        });
        setIsExistingPostalCode(false);
      }
    }
  }, [postalcode]);

  useEffect(() => {
    if (initialMunicipilityRender.current) {
      initialMunicipilityRender.current = false;
    } else {
      if (municipility?.data?.length > 0) {
        const { municipalityName, municipalityCode } = municipility?.data[0];
        setdebtorData({
          ...mapDataFormData(formData),
          municipalityCode,
          municipalityName,
        });
        setIsExistingMunicipalityCode(true);
      } else {
        setdebtorData({
          ...mapDataFormData(formData),
          municipalityName: "",
        });
        setIsExistingMunicipalityCode(false);
      }
    }
  }, [municipility]);

  const searchCreditor = (e: any, values: any) => {
    if (e.target.value?.length > 0 && !isNaN(e.target.value)) {
      getCreditor({
        roleType: "CRE",
        searchCriteria: "Number",
        keyword: e.target.value,
        formValues: values,
      });
    } else {
      setCreditorData({
        creditorNo: "",
        fullAddress: "",
        fullName: "",
      });
      setdebtorData(initailEntity);
    }
  };

  const changeCreditorStatus = (e: any, rest: any) => {
    setStatus({ status: true });
    rest?.setFieldValue("creditorNo", e.target?.value);
  };
  const selectCreditor = (data: any, rest: any) => {
    rest?.setFieldValue("creditorNo", data?.creditorNo);
    setCreditorData(data);
    setdebtorData(initailEntity);
  };

  const selectDebtor = (data: any) => {
    setdebtorData(data);
    setIsExistingPostalCode(true);
    setIsExistingMunicipalityCode(true);
    setCountry({
      prevoius: "",
      current: data?.countryCode,
    });
  };
  const checkFieldEnble = (data: any): boolean => {
    return (
      (data?.creditorNo?.length > 0 ||
        creditor?.data?.creditorNo?.length > 0) &&
      isValidCreditor &&
      creditorData?.fullName?.length > 0
    );
  };
  const searchDebtor = (e: any, values: any) => {
    if (!isNaN(e.target.value)) {
      if (e.target.value?.length > 0) {
        getDebtor({
          roleType: "DEB",
          searchCriteria: "cid",
          keyword: e.target.value,
          formValues: values,
        });
      } else {
        setdebtorData(initailEntity);
      }
    }
  };

  const countryPopOverConfirmYes = (rest: any, values: any) => {
    SetPopConfirmVisible(false);
    rest.setFieldValue("address1", "");
    rest.setFieldValue("address2", "");
    rest.setFieldValue("address3", "");
    rest.setFieldValue("zipName", "");
    rest.setFieldValue("zipCode", "");
    rest.setFieldValue("municipalityName", "");
    rest.setFieldValue("municipalityCode", "");
  };

  const countryPopOverConfirmNo = (rest: any) => {
    SetPopConfirmVisible(false);
    rest.setFieldValue("country", country.prevoius);
  };
  const onChangeCountry = (data: any, rest: any, values: any) => {
    setCountry({
      prevoius: country.current,
      current: data,
    });
    rest.setFieldValue("country", data);
    if (values?.zipCode.length > 0) {
      SetPopConfirmVisible(true);
    }
  };
  const onSelectTransactionType = (e: any, rest: any) => {
    if (e) {
      rest.setFieldValue(`transations[${transactionIndex}].tType`, e.typeName);
    }
    setTransactionTypeVisible(false);
  };

  const openTransactionType = (index: number) => {
    setTransactionIndex(index);
    setTransactionTypeVisible(true);
  };
  const closeTransaction = () => {
    setTransactionTypeVisible(false);
  };

  const setworkFlowOption = (): Array<any> => {
    return workflows?.data?.map(({ displayName, id }: any) => {
      return {
        label: displayName,
        value: id,
      };
    });
  };

  const handlePersonNo = (data: any, rest: any, values: any) => {
    rest.setFieldValue("birthDate", moment(data));
  };

  const formatOrganizationNo = (data: any, rest: any) => {
    try {
      const value = data.target.value;
      if (value?.length === 9) {
        const parts = value.replace(/\s/g, "").match(/.{1,3}/g);
        rest.setFieldValue("nIN", parts.join(" "));
      }
    } catch (error) {
      appInsights.trackException(
        `Error in formatOrganizationNo function in Invoice registration. - ${error}`
      );
    }
  };
  const formatPersonNo = (value: any, rest: any) => {
    try {
      if (value?.length === 6 || (value?.length >= 9 && value?.length <= 11)) {
        const parts = value.replace(/\s/g, "").match(/.{1,6}/g);
        rest.setFieldValue("nIN", parts.join(" "));
      }
    } catch (error) {
      appInsights.trackException(
        `Error in formatPersonNo function in Invoice registration. - ${error}`
      );
    }
  };
  const handleBirthday = (values: any, rest: any) => {
    try {
      const personNo = values.target.value;
      const data = validatePersonNo(personNo.replaceAll(/\D/g, ""));
      if (data.isValid) {
        formatPersonNo(personNo, rest);
        rest.setFieldValue("birthDate", moment(data.birthDate));
      } else {
        rest.setFieldValue("birthDate", "");
      }
    } catch (error) {
      appInsights.trackException(
        `Error in handleBirthday function in Invoice registration. - ${error}`
      );
    }
  };

  const onChangeTargetDate = (date: any, rest: any) => {
    rest.setFieldValue("targetDate", date);
  };

  const setHeaderInfo = (data: any) => {
    try {
      return {
        PID: data?.creditorNo,
        FileType: "IN",
        FileCreator: currentUser?.unique_name,
        FileVersion: "1.0",
        GeneratorID: "0001" /***************/,
        ErrorHandling: "",
        MessageID: `UnicornBox_${Guid.create()}`,
        InternalMessageID: "",
        ClaimsStatus: "",
        ClientCulture: "nb-no",
        ImportProfile: "Standard",
        Checksums: {
          NumberOfInvoices: data?.transations?.filter(
            ({ tType }: any) => tType === 3 || tType === 4
          )?.length,
          NumberOfCustomers: 1,
          TotalMainAmount: data?.transations
            ?.filter(({ tType }: any) => tType === 3 || tType === 4)
            ?.map(({ tAmount }: any) => getNumber(tAmount))
            .reduce((acc: any, curr: any) => acc + curr, 0),
        },
      };
    } catch (error) {
      appInsights.trackException(
        `Error in setHeaderInfo function in Invoice registration. - ${error}`
      );
      return {};
    }
  };
  const setCustomerInfo = (data: any) => {
    try {
      const {
        creditorNo,
        cid,
        lastName,
        firstName,
        debtorType,
        birthDate,
        nIN,
        sms,
        tel,
        email,
        address1,
        address2,
        address3,
        zipCode,
        zipName,
        country,
      } = data;
      return [
        {
          PID: creditorNo,
          CID: cid,
          CType: "Customer",
          CLastName: lastName,
          CFirstName: firstName,
          CDebtorType: debtorType?.toLowerCase() === "person" ? "P" : "N",
          CBirthdate: moment(birthDate).isValid()
            ? moment(birthDate).format(dateFormats.req)
            : "",
          CSocialSecurityNo: debtorType?.toLowerCase() === "person" ? nIN : "",
          COrganisation: debtorType?.toLowerCase() === "person" ? "" : nIN,
          CMobile: "",
          CSMS: sms,
          CHome: tel,
          CWork: "",
          CMail: email,
          AddressList: [
            {
              PID: creditorNo,
              CID: cid,
              CAddressSource: "UnicornBox",
              CAdress1: address1,
              CAdress2: address2,
              CAdress3: address3,
              CZIP: zipCode,
              CCity: zipName,
              CCountry: country,
            },
          ],
        },
      ];
    } catch (error) {
      appInsights.trackException(
        `Error in setCustomerInfo function in Invoice registration. - ${error}`
      );
      return [];
    }
  };
  const setCaseInfo = (data: any) => {
    try {
      const { creditorNo, cid, startWorkflowAt, targetDate, transations } =
        data;
      return [
        {
          PID: creditorNo,
          CID: cid,
          CID2: cid,
          CaseId: "",
          CaseDebtWarning:
            claimType === "Invoice"
              ? ""
              : setDWDate(transations),
          CaseType: claimType === "Invoice" ? "S" : "C",
          CaseReportGroup: "Group1",
          CaseStatus: "open",
          PayNoteDate: claimType === "Invoice" ? "" : setBODate(transations),
          StartingState: startWorkflowAt === "" ? 0 : startWorkflowAt,
          ExecutionStartDate: moment(targetDate).isValid()
            ? moment(targetDate).format(dateFormats.req)
            : "",
          CustomerList: setCustomerInfo(data),
          TransactionList: setTransactionInfo(data),
          NoteList: [],
          IItemList: [],
          CustomSettings: [],
        },
      ];
    } catch (error) {
      appInsights.trackException(
        `Error in setCaseInfo function in Invoice registration. - ${error}`
      );
      return [];
    }
  };

  const setTransactionInfo = (data: any) => {
    const { transations, creditorNo, cid } = data;
    try {
      return transations?.map(
        ({
          tType,
          tNumber,
          tDate,
          tDueDate,
          tDWDate,
          tAmount,
          tInfo,
          tInterestRate,
          tInterestDate,
          tKid,
        }: any) => {
          return {
            PID: creditorNo,
            CID: cid,
            TType: tType,
            TNumber: tNumber,
            TDate: moment(tDate).isValid()
              ? moment(tDate).format(dateFormats.req)
              : "",
            TDueDate: moment(tDueDate).isValid()
              ? moment(tDueDate).format(dateFormats.req)
              : "",
            TDWDate: moment(tDWDate).isValid()
              ? moment(tDWDate).format(dateFormats.req)
              : "",
            TAmount: getNumber(tAmount),
            TInfo: tInfo,
            TInterestRate: tInterestRate === "" ? 0 : tInterestRate,
            TInterestDate: moment(tInterestDate).isValid()
              ? moment(tInterestDate).format(dateFormats.req)
              : "",
            TKid: tKid,
            TCreditInvoice: "N/A",
            TStatus: "",
            TCreditNoteInvoiceRef: "",
            SubTType: 0,
            CustomSettings: [],
          };
        }
      );
    } catch (error) {
      appInsights.trackException(
        `Error in setTransactionInfo function in Invoice registration. - ${error}`
      );
      return [];
    }
  };
  const handleSubmit = (values: any, actions: any) => {
    setFormData(values);
    const reqBody = {
      HeaderInfo: setHeaderInfo(values),
      CaseList: setCaseInfo(values),
    };
    register(reqBody);
  };

  const formReset = (rest: any) => {
    setCreditorData({
      creditorNo: "",
      fullAddress: "",
      fullName: "",
    });
    setdebtorData(initailEntity);
    setIsExistingMunicipalityCode(true);
    setIsExistingPostalCode(true);
    resetClaim({});
    rest.setFieldValue("creditorNo", "");
  };

  const getNumber = (value: any): number => {
    const returnVal = validateNumber(value, currentLanguage);
    return returnVal && returnVal > 0 ? returnVal : 0;
  };

  const openPostalArea = () => {
    setPostalAreaVisible(true);
  };
  const postalAreaClose = () => {
    setPostalAreaVisible(false);
  };

  const handlePostalDoubleClick = (
    selectedPostalAreaDetail: any,
    rest: any,
    values: any
  ) => {
    setPostalAreaVisible(false);
    rest.setFieldValue("zipCode", selectedPostalAreaDetail?.PostalCode);
    rest.setFieldValue("zipName", selectedPostalAreaDetail?.City);
    rest.setFieldValue(
      "municipalityCode",
      selectedPostalAreaDetail?.MunicipalityCode
    );
    rest.setFieldValue(
      "municipalityName",
      selectedPostalAreaDetail?.MunicipalityName
    );
  };

  const [creditorDrawerVisible, setCreditorDrawerVisible] =
    useState<boolean>(false);
  const openCreditor = () => {
    setStatus({ status: true });
    setCreditorDrawerVisible(true);
  };
  const closeCreditor = () => {
    setCreditorDrawerVisible(false);
  };

  const [debtorDrawerVisible, setDebtorDrawerVisible] =
    useState<boolean>(false);
  const openDebtor = () => {
    setDebtorDrawerVisible(true);
  };
  const closeDebtor = () => {
    setDebtorDrawerVisible(false);
  };
  const changeClaimType = (type: string, rest: any): void => {
    rest.setFieldValue("registerAs", type);
    getTransactionTypes(
      type === "Invoice" ? "subcaseRegistration" : "caseRegistration"
    );
    getWorkflowState({ workflowEntity: type === "Invoice" ? "S" : "C" });
    setClaimType(type);
  };

  const searchZipDetail = (zipcode: string, values: any, rest: any) => {
    if (zipcode?.length > 0) {
      setFormData(values);
      if (searchPostalcode) {
        searchPostalcode({
          countryCode: values?.country ?? "NO",
          searchText: zipcode,
        });
      }
    } else {
      rest.setFieldValue("zipName", "");
      rest.setFieldValue("municipalityName", "");
      rest.setFieldValue("municipalityCode", "");
    }
  };

  const searchMunicipilityDetail = (
    countyCode: string,
    values: any,
    rest: any
  ) => {
    if (countyCode?.length > 0) {
      setFormData(values);
      if (searchMunicipility) {
        searchMunicipility({
          countryCode: values?.country ?? "NO",
          searchText: countyCode,
        });
      }
    } else {
      rest.setFieldValue("municipalityName", "");
    }
  };

  /**
   * Set value for BO Date
   * @param transactions transactions details
   * @returns BO date
   */
  const setBODate = (transactions: any): string => {
    try {
      return moment(
        transactions.find(
          ({ tBODate }: any) =>
            tBODate !== "" && tBODate !== undefined && tBODate !== null
        ).tBODate
      ).format(dateFormats.req);
    } catch (error) {
      appInsights.trackException(
        `Error in setBODate function in Invoice registration. - ${error}`
      );
      return "1900-01-01";
    }
  };

  /**
   * Set value for DW Date
   * @param transactions transactions details
   * @returns DW date
   */
   const setDWDate = (transactions: any): string => {
    try {
      return moment(
        transactions.find(
          ({ tDWDate, tType }: any) =>
          tType == 3 && tDWDate !== "" && tDWDate !== undefined && tDWDate !== null
        ).tDWDate
      ).format(dateFormats.req);
    } catch (error) {
      appInsights.trackException(
        `Error in setDWDate function in Invoice registration. - ${error}`
      );
      return "1900-01-01";
    }
  };

  /**
   * @function
   * @description handle back button navigation
   */
  const onBack=():void=>{
    const {length,push}=history
    length <=1 ?push('/'):goBack()
  }
  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        registerAs: claimType,
        creditorNo: creditorData?.creditorNo,
        cid: debtorData.cid,
        firstName: debtorData.firstName,
        lastName: debtorData.lastName,
        nIN: debtorData?.nIN,
        birthDate: debtorData.birthDate,
        address1: debtorData.address1,
        address2: debtorData.address2,
        address3: debtorData.address3,
        zipCode: debtorData.zipCode,
        zipName: debtorData.zipName,
        country: debtorData.countryCode,
        tel: debtorData.contacts?.Home,
        municipalityCode: debtorData?.municipalityCode,
        municipalityName: debtorData?.municipalityName,
        sms: debtorData.contacts?.sms,
        email: debtorData?.emailAddress,
        debtorType: debtorData?.debtorType,
        transations: [initials],
        startWorkflowAt:
          _.find(workflows.data, { name: "StateFinder" })?.id ?? "",
        targetDate: null,
        ...registeredCase.data,
      }}
      validationSchema={ValidationSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        handleRest,
        isSubmitting,
        isValidating,
        ...rest
      }: any) => (
        <$Form>
          <div className="register-invoice">
            <div>
              <$Affix offsetTop={48}>
                <div className="page-header header-border">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex align-items-center pl-3">
                      <$PageHeader
                        className="px-0"
                        title={t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.REGISTER_INVOICE"
                        )}
                        onBack={onBack}
                      />
                      <$Divider className="bui-devider" type="vertical" />
                      <$Skeleton loading={false} active paragraph={{ rows: 0 }}>
                        <$Button
                          type="primary"
                          size="small"
                          className="mr-2 px-4"
                          onClick={handleSubmit}
                          disabled={registeredCase.isLoading}
                          loading={registeredCase.isLoading}
                        >
                          {t("US.COLLECTION.COMMON:COMMON.REGISTER")}
                        </$Button>
                      </$Skeleton>
                      <$Skeleton loading={false} active paragraph={{ rows: 0 }}>
                        <$Button
                          type="dashed"
                          size="small"
                          className="mr-2 px-4"
                          onClick={() => formReset(rest)}
                        >
                          {t("US.COLLECTION.COMMON:COMMON.RESET")}
                        </$Button>
                      </$Skeleton>
                    </div>
                  </div>
                </div>
              </$Affix>
            </div>
            <div className="mb-0">
              <$Row gutter={0}>
                <$Col
                  xxl={{ span: 8, offset: 0 }}
                  xl={{ span: 10, offset: 0 }}
                  className="left-side"
                >
                  <div className="px-4 py-3 pb-5">
                    <div className="">
                      {t(
                        "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.REGISTER_AS"
                      )}
                    </div>
                    <div className="mt-1">
                      <$Radio
                        name="registerAs"
                        size="large"
                        className="register-as"
                        options={[
                          {
                            label: t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.INVOICE"
                            ),
                            value: "Invoice",
                          },
                          {
                            label: t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CASE"
                            ),
                            value: "Case",
                          },
                        ]}
                        radioButton="true"
                        optionValue="value"
                        optionContent={(item: any, index: number) => item.label}
                        onChange={(e: any) =>
                          changeClaimType(e?.target?.value, rest)
                        }
                      />
                    </div>
                    <Creditor
                      viewCreditors={openCreditor}
                      searchCreditor={searchCreditor}
                      data={creditorData}
                      values={values}
                      rest={rest}
                      changeCreditorStatus={changeCreditorStatus}
                    />
                    <$Row gutter={8} className="mt-1">
                      <$Col span={8}>
                        {checkFieldEnble(values) && (
                          <a
                            onClick={openDebtor}
                            className="d-block"
                            style={{ marginBottom: "2px" }}
                          >
                            <span className="required">*</span>{" "}
                            <strong>
                              {t(
                                "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DEBTOR_NO"
                              )}
                            </strong>
                          </a>
                        )}
                        {!checkFieldEnble(values) && (
                          <div style={{ marginBottom: "2px" }}>
                            <span className="required">*</span>{" "}
                            <strong>
                              {t(
                                "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DEBTOR_NO"
                              )}
                            </strong>
                          </div>
                        )}
                        <$Input
                          name="cid"
                          size="small"
                          disabled={!checkFieldEnble(values)}
                          onBlur={(e: any) => searchDebtor(e, values)}
                        />
                      </$Col>
                      <$Col span={8} className="pr-5">
                        <$Select
                          formitem={{
                            label: t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DEBTOR_TYPE"
                            ),
                          }}
                          name="debtorType"
                          size="small"
                          disabled={!checkFieldEnble(values)}
                          allOption={false}
                          style={{ width: "100%" }}
                          options={[
                            {
                              label: t(
                                "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.PERSON"
                              ),
                              value: "Person",
                            },
                            {
                              label: t(
                                "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.COMPANY"
                              ),
                              value: "Company",
                            },
                          ]}
                          onChange={() => {
                            rest.setFieldValue("nIN", "");
                          }}
                        />
                      </$Col>
                    </$Row>
                    <$Row gutter={16} className="mt-1">
                      <$Col span={12}>
                        <$Input
                          name="firstName"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.FIRST_NAME"
                          )}
                        />
                      </$Col>
                      <$Col span={12}>
                        <$Input
                          name="lastName"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                          required
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.LAST_NAME"
                          )}
                        />
                      </$Col>
                    </$Row>
                    <$Row gutter={16} className="mt-1">
                      <$Col span={12}>
                        <$Input
                          name="nIN"
                          size="small"
                          disabled={!checkFieldEnble(values)}
                          onBlur={
                            values?.debtorType === "Person"
                              ? (value: any) => handleBirthday(value, rest)
                              : (value: any) =>
                                  formatOrganizationNo(value, rest)
                          }
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.NIN"
                          )}
                        />
                      </$Col>
                      <$Col span={12}>
                        {values?.debtorType === "Person" && (
                          <$DatePicker
                            allowClear={true}
                            placeholder={t(
                              "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                            )}
                            name="birthDate"
                            disabled={!checkFieldEnble(values)}
                            label={t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.BIRTH_DATE"
                            )}
                            format={currentDateFormat}
                            defaultPickerValue={moment("2000/01/01")}
                            onChange={(e: any) =>
                              handlePersonNo(e, rest, values)
                            }
                            value={
                              values.birthDate === "1900-01-01T00:00:00"
                                ? null
                                : moment(values.birthDate).isValid()
                                ? moment(values.birthDate)
                                : null
                            }
                            style={{ width: "100%" }}
                            size="small"
                            disabledDate={(date: any) =>
                              !date || date > moment().startOf("day")
                            }
                          />
                        )}
                      </$Col>
                    </$Row>
                    <div className="mt-4">
                      <strong>
                        {t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.ADDRESS"
                        )}
                      </strong>
                    </div>
                    <$Row gutter={16} className="mt-3">
                      <$Col span={12}>
                        <$Popover
                          placement="rightTop"
                          content={
                            <div style={{ maxWidth: "300px" }}>
                              <div className="ant-popover-message">
                                <ExclamationCircleFilled />
                                <div className="ant-popover-message-title">
                                  <div style={{ paddingBottom: "0.3rem" }}>
                                    {t(
                                      "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.COUNTRY_CHANGE_WARNING"
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "0.5rem" }}>
                                    <strong>
                                      {t(
                                        "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.DO_YOU_WANT_TO_CONTINUE"
                                      )}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className="ant-popover-buttons">
                                <$Button
                                  size="small"
                                  onClick={() => countryPopOverConfirmNo(rest)}
                                >
                                  {t("US.COMMON:COMMON.NO")}
                                </$Button>
                                <$Button
                                  size="small"
                                  type="primary"
                                  onClick={() =>
                                    countryPopOverConfirmYes(rest, values)
                                  }
                                >
                                  {t("US.COMMON:COMMON.YES")}
                                </$Button>
                              </div>
                            </div>
                          }
                          trigger="click"
                          visible={popConfirmVisible}
                        >
                          <$SelectGroup
                            className="country-select"
                            required
                            disabled={!checkFieldEnble(values)}
                            options={_.orderBy(
                              europeanCountry,
                              ["name", "label"],
                              ["asc", "asc"]
                            )}
                            formitem={{
                              label: t("US.COLLECTION.CASE:OTHERPARTY.COUNTRY"),
                            }}
                            style={{ width: "100%" }}
                            value={values.country}
                            name="country"
                            optionFilterProp="children"
                            onChange={(data: any) =>
                              onChangeCountry(data, rest, values)
                            }
                            filterOption={(input: any, option: any) =>
                              option.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </$Popover>
                      </$Col>
                    </$Row>
                    <$Row gutter={16}>
                      <$Col span={8}>
                        <$Input
                          name="address1"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.LINE_1"
                          )}
                        />
                      </$Col>
                      <$Col span={8}>
                        <$Input
                          name="address2"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.LINE_2"
                          )}
                        />
                      </$Col>
                      <$Col span={8}>
                        <$Input
                          name="address3"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.LINE_3"
                          )}
                        />
                      </$Col>
                    </$Row>
                    <$Row gutter={16} className="mt-1">
                      <$Col span={12}>
                        {checkFieldEnble(values) && (
                          <a
                            className="d-block"
                            style={{ paddingBottom: "0.13rem" }}
                            onClick={() => openPostalArea()}
                          >
                            {values.country === "NO" && (
                              <span className="required">* </span>
                            )}
                            <strong>
                              {t(
                                "US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE"
                              )}
                            </strong>
                          </a>
                        )}
                        {!checkFieldEnble(values) && (
                          <>
                            {values.country === "NO" && (
                              <span className="required">* </span>
                            )}
                            <strong>
                              {t(
                                "US.COLLECTION.COMMON:ENTITYSELECTION.POSTALCODE"
                              )}
                            </strong>
                          </>
                        )}

                        <$Skeleton
                          loading={false}
                          active
                          paragraph={{ rows: 0 }}
                        >
                          <$Input
                            name="zipCode"
                            disabled={!checkFieldEnble(values)}
                            size="small"
                            required
                            onBlur={(data: any) =>
                              searchZipDetail(data?.target?.value, values, rest)
                            }
                          />
                        </$Skeleton>
                      </$Col>
                      <$Col span={12}>
                        <$Skeleton
                          loading={postalcode?.isLoading}
                          active
                          paragraph={{ rows: 0 }}
                        >
                          <$Input
                            name="zipName"
                            disabled={
                              !checkFieldEnble(values) || isExistingPostalCode
                            }
                            size="small"
                            label={t(
                              "US.COLLECTION.COMMON:ENTITYSELECTION.POSTALAREA"
                            )}
                          />
                        </$Skeleton>
                      </$Col>
                    </$Row>
                    <$Row gutter={16} className="mt-1">
                      <$Col span={12}>
                        <$Skeleton
                          loading={postalcode?.isLoading}
                          active
                          paragraph={{ rows: 0 }}
                        >
                          <$Input
                            name="municipalityCode"
                            disabled={
                              !checkFieldEnble(values) || isExistingPostalCode
                            }
                            size="small"
                            onBlur={(data: any) =>
                              searchMunicipilityDetail(
                                data?.target?.value,
                                values,
                                rest
                              )
                            }
                            label={t(
                              "US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYCODE"
                            )}
                          />
                        </$Skeleton>
                      </$Col>
                      <$Col span={12}>
                        <$Skeleton
                          loading={
                            postalcode?.isLoading || municipility.isLoading
                          }
                          active
                          paragraph={{ rows: 0 }}
                        >
                          <$Input
                            name="municipalityName"
                            disabled={
                              !checkFieldEnble(values) ||
                              isExistingMunicipalityCode
                            }
                            size="small"
                            label={t(
                              "US.COLLECTION.COMMON:ENTITYSELECTION.MUNICIPALITYNAME"
                            )}
                          />
                        </$Skeleton>
                      </$Col>
                    </$Row>
                    <div className="mt-4">
                      <strong>
                        {t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CONTACTS"
                        )}
                      </strong>
                    </div>
                    <$Row gutter={16} className="mt-3">
                      <$Col span={6}>
                        <span className="d-block pt-1">
                          {t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TELEPHONE"
                          )}
                        </span>
                      </$Col>
                      <$Col span={10}>
                        <$Input
                          name="tel"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                        />
                      </$Col>
                    </$Row>
                    <$Row gutter={16}>
                      <$Col span={6}>
                        <span className="d-block pt-1">
                          {t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.SMS")}
                        </span>
                      </$Col>
                      <$Col span={10}>
                        <$Input
                          name="sms"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                        />
                      </$Col>
                    </$Row>
                    <$Row gutter={16}>
                      <$Col span={6}>
                        <span className="d-block pt-1">
                          {t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.EMAIL"
                          )}
                        </span>
                      </$Col>
                      <$Col span={10}>
                        <$Input
                          name="email"
                          disabled={!checkFieldEnble(values)}
                          size="small"
                        />
                      </$Col>
                    </$Row>
                  </div>
                </$Col>
                <$Col
                  xxl={{ span: 16, offset: 0 }}
                  xl={{ span: 14, offset: 0 }}
                  className="right-side"
                >
                  <div className="px-3">
                    <div className="mt-3">
                      <strong>
                        {t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TRANSACTION_INFORMATION"
                        )}
                      </strong>
                    </div>
                    <div className="mt-3">
                      <TransactionSetUp
                        currentDateFormat={currentDateFormat}
                        currentCurrency={currentCurrency}
                        currentLanguage={currentLanguage}
                        values={values}
                        rest={rest}
                        disabled={!checkFieldEnble(values)}
                        openTransactionType={openTransactionType}
                        transactionTypes={
                          transTypes?.data?.length > 0
                            ? transTypes?.data?.map((item: any) => {
                                return {
                                  label: item?.typeName,
                                  value: item?.typeId,
                                };
                              })
                            : []
                        }
                      />
                    </div>
                    <div className="mt-4">
                      <strong>
                        {t(
                          "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CLAIM_PROCEEDING"
                        )}
                      </strong>
                    </div>
                    <$Row gutter={16} className="mt-3">
                      <$Col span={5}>
                        <$Select
                          formitem={{
                            label: t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.START_WORKFLOW_AT"
                            ),
                          }}
                          name="startWorkflowAt"
                          size="small"
                          value={values?.startWorkflowAt}
                          allOption={false}
                          style={{ width: "100%" }}
                          disabled={!checkFieldEnble(values)}
                          options={setworkFlowOption()}
                        />
                      </$Col>
                      <$Col span={5}>
                        <$DatePicker
                          name="targetDate"
                          label={t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TARGET_DATE"
                          )}
                          placeholder={t(
                            "US.COLLECTION.COMMON:COMMON.SELECT_DATE"
                          )}
                          format={currentDateFormat}
                          disabled={
                            !(
                              values?.startWorkflowAt?.toString()?.length > 0 &&
                              checkFieldEnble(values)
                            )
                          }
                          style={{ width: "100%" }}
                          size="small"
                          disabledDate={(date: any) =>
                            !date || date.isBefore(moment().startOf("day"))
                          }
                          allowClear={true}
                          onChange={(e: any) => onChangeTargetDate(e, rest)}
                          value={
                            moment(values?.targetDate).isValid()
                              ? moment(values?.targetDate)
                              : null
                          }
                          required={true}
                        />
                      </$Col>
                    </$Row>
                    <div className="ri-summary mt-3 mb-3">
                      <div className="mt-0">
                        <strong>
                          {t(
                            "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.SUMMARY"
                          )}
                        </strong>
                      </div>
                      <$Row gutter={16} className="mt-2">
                        <$Col span={12}>
                          <div className="dom-amt-list-table">
                            <div className="list-table-row">
                              <div
                                className="list-table-col"
                                style={{ width: "160px" }}
                              >
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.MAIN_AMOUNT"
                                )}
                              </div>
                              <div className="list-table-col">
                                <$AmountLabel
                                  value={values?.transations
                                    ?.filter(({ tType }: any) => tType === 3)
                                    ?.map(({ tAmount }: any) =>
                                      getNumber(tAmount)
                                    )
                                    .reduce(
                                      (acc: any, curr: any) => acc + curr,
                                      0
                                    )}
                                />
                              </div>
                            </div>
                            <div className="list-table-row">
                              <div
                                className="list-table-col"
                                style={{ width: "160px" }}
                              >
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.OTHER_AMOUNT"
                                )}
                              </div>
                              <div className="list-table-col">
                                <$AmountLabel
                                  value={values?.transations
                                    ?.filter(({ tType }: any) => tType !== 3)
                                    ?.map(({ tAmount }: any) =>
                                      getNumber(tAmount)
                                    )
                                    .reduce(
                                      (acc: any, curr: any) => acc + curr,
                                      0
                                    )}
                                />
                              </div>
                            </div>

                            <div className="list-table-row">
                              <div
                                className="list-table-col"
                                style={{ width: "160px" }}
                              >
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.TOTAL"
                                )}
                              </div>
                              <div className="list-table-col">
                                <$AmountLabel
                                  value={values?.transations
                                    ?.map(({ tAmount }: any) =>
                                      getNumber(tAmount)
                                    )
                                    .reduce(
                                      (acc: any, curr: any) => acc + curr,
                                      0
                                    )}
                                />
                              </div>
                            </div>
                          </div>
                        </$Col>
                        <$Col span={12}>
                          {values?.startWorkflowAt?.toString()?.length > 0 &&
                            moment(values?.targetDate).isValid() && (
                              <p className="ri-summary-msg">
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.THIS_CLAIM_WILL_BE_IN"
                                )}{" "}
                                <strong>
                                  {
                                    workflows?.data?.filter(
                                      ({ id }: any) =>
                                        id === values?.startWorkflowAt
                                    )[0]?.displayName
                                  }
                                </strong>{" "}
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.AFTER_SAVING_THE_DATA"
                                )}
                                .<br></br>
                                {t(
                                  "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.THE_FIRST_STEP_OF_THE_CLAIM_IS_EXPECTED_TO_BE_ON"
                                )}{" "}
                                <strong>
                                  <$DateLabel value={values?.targetDate} />{" "}
                                </strong>
                              </p>
                            )}
                        </$Col>
                      </$Row>
                    </div>
                    {registeredCase.data.length > 0 && (
                      <>
                        {" "}
                        <div className="mb-1 mt-4">
                          <strong>
                            {t(
                              "US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.REGISTERED_CLAIMS"
                            )}
                          </strong>
                        </div>
                        <div className="d-flex flex-wrap registered-claims">
                          {registeredCase.data.map(
                            (
                              { caseType, caseNo, externalCaseNo }: any,
                              index: number
                            ) => (
                              <a
                                className="registered-claims-item"
                                onClick={() =>
                                  getRouteUrl.caseType(externalCaseNo)
                                }
                                key={index}
                              >
                                <BoxIcons
                                  type={IconTypes.BOX_ICON}
                                  name="case-subcase"
                                />
                                <span>{externalCaseNo}</span>
                              </a>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </$Col>
              </$Row>
            </div>
          </div>

          {/* Add Creditor ---------------------------------------------------------------------------------*/}
          <$Drawer
            width={600}
            visible={creditorDrawerVisible}
            onClose={closeCreditor}
            title={t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.ADD_CREDITOR")}
            bodyStyle={{ marginBottom: "0px" }}
            destroyOnClose
          >
            <CreditorTable
              selectCreditor={(data: any) => selectCreditor(data, rest)}
              onClose={closeCreditor}
            />
          </$Drawer>
          {/* Add Debtor ---------------------------------------------------------------------------------*/}
          <$Drawer
            width={1200}
            visible={debtorDrawerVisible}
            onClose={closeDebtor}
            title={t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.ADD_DEBTOR")}
            bodyStyle={{ marginBottom: "0px" }}
            destroyOnClose
          >
            <DebtorTable
              selectDebtor={selectDebtor}
              creditorInkassoId={values?.creditorNo}
              onClose={closeDebtor}
            />
          </$Drawer>
          {/* Postal Area ---------------------------------------------------------------------------------*/}
          <$Drawer
            title={drawerName}
            width={800}
            visible={PostalAreaVisible}
            onClose={postalAreaClose}
            destroyOnClose
          >
            <PostalArea
              postalAreaClose={() => postalAreaClose()}
              drawerContentChangeClick={(e: any) =>
                handlePostalDoubleClick(e?.updatedInfo, rest, values)
              }
              {...props}
              countryCode={values.country}
            />
          </$Drawer>
          {/* Trans Type ---------------------------------------------------------------------------------*/}
          <$Drawer
            title={t("US.COLLECTION.TRANSACTIONS:REGISTER.TRANSACTION_TYPES")}
            width={800}
            visible={transactionTypeVisible}
            onClose={closeTransaction}
            destroyOnClose
          >
            <TransTypes
              onSelectTransactionType={(e:any) => onSelectTransactionType(e, rest)}
              enableHeader={false}
            />
          </$Drawer>
        </$Form>
      )}
    </Formik>
  );
});

const mapStateToProps = (state: IRootState) => {
  const { common, invoice, entityDetails, transType } = state;
  const { currentLanguage, currentDateFormat, currentCurrency } = common;
  const {
    creditor,
    debtor,
    isValidCreditor,
    workflows,
    registeredCase,
    postalcode,
    municipility,
  } = invoice;
  const { postalAreas, drawerName } = entityDetails;
  const { transTypes } = transType;
  return {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    creditor,
    debtor,
    isValidCreditor,
    postalAreas,
    drawerName,
    transTypes,
    workflows,
    registeredCase,
    postalcode,
    municipility,
  };
};

const mapDispatchToProps = {
  getCreditor: creditor.self.get,
  setStatus: creditor.self.set,
  getDebtor: debtor.self.get,
  getTransactionTypes: ActionTrans.transactionTypes.get,
  getWorkflowState: invoice.workflows.get,
  register: invoice.invoices.save,
  resetClaim: invoice.invoices.reset,
  searchPostalcode: invoice.postalCode.search,
  searchMunicipility: invoice.minicipality.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInvoice);
