import { IEntity } from '../Interfaces'

export const initailEntity: IEntity = {
    entityId: '',
    entRoleId: '',
    roleType: '',
    firstName: '',
    lastName: '',
    cid:'',
    nIN: '',
    birthDate: '',
    address1: '',
    address2: '',
    address3: '',
    zipCode: '',
    zipName: '',
    debtorType:'Person',
    emailAddress: '',
    municipalityCode: '',
    municipalityName: '',
    countryCode: 'NO',
    isAddressUnknown: false,
    reference: '',
    phoneNumbers: [],
    contacts: {}
}