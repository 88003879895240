import React from 'react';
import { Route } from 'react-router-dom';

const ClaimImportStatus = React.lazy(() => import('../containers/ClaimImportStatus'));
const CustomDataFields = React.lazy(() => import('../containers/CustomDataFields'));
const GenerateInterface = React.lazy(() => import('../containers/GenerateInterface'));
const ImportClaimsAndPayments = React.lazy(() => import('../containers/ImportClaimsAndPayments'));
const ManageMapFiles = React.lazy(() => import('../containers/ManageMapFiles'));
const RegisterInvoice = React.lazy(() => import('../containers/RegisterInvoice'));
const ATGHome = React.lazy(() => import('../containers/ATG'))

export default [    
    < Route exact key='/claims-import-status' path='/claims-import-status' render={() => <ClaimImportStatus />} />,
    < Route exact key='/custom-data-fields' path={'/custom-data-fields'} render={() => <CustomDataFields />} />,
    < Route exact key='/generate-interface' path={'/bugenerate-interfacelk'} render={() => <GenerateInterface />} />,
    < Route exact key='/import-claims-and-payments' path={'/import-claims-and-payments'} render={() => <ImportClaimsAndPayments />} />,
    < Route exact key='/manage-map-files' path={'/manage-map-files'} render={() => <ManageMapFiles />} />,
    < Route exact key='/register-invoice' path={'/register-invoice'} render={() => <RegisterInvoice />} />,
    < Route exact key='/atg' path={'/atg'} render={() => <ATGHome />} />,
    < Route exact key='/' path={'/'} render={() => <></>} />

]