import { takeLatest, put, call, select, take } from "redux-saga/effects";
import * as API from "us.collection.input-output/services";
import * as Actions from "us.collection.input-output/actions";
import {
  getCreditorGroups,
  getCreditorCompanyByGroupId,
} from "us.common/actions";
import * as Constants from "us.common/constants/User";
import { ATG } from "us.collection.input-output/constants/Actions";
import { IRootState } from "us.collection/interfaces";
import { $Message } from "us.common/components";
import { b64toBlob } from "us.collection.input-output/functions";

const { atg } = Actions;
const {
  GET_CREDITOR_GROUP_SUCCESS,
  GET_CREDITOR_GROUP_FAIL,
  GET_CREDITOR_COMPANY_SUCCESS,
  GET_CREDITOR_COMPANY_FAIL,
} = Constants.User;

const ATGSagas = {
  atgs: {
    search: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.atg.atgs.search, params);
        if (
          !Array.isArray(data) &&
          (data?.code == 400 || data?.code == 404 || data?.errors?.length != 0)
        ) {
          yield put(atg.atgs.fail(data));
          $Message.error(data?.errors[0].error);
        } else {
          yield put(atg.atgs.success(data));
        }
      } catch (error) {
        yield put(atg.atgs.fail(error));
        console.log(error);
      }
    },
  },
  atg: {
    download: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        const data = yield call(API.atg.atg.download, params);
        console.log("jajaj", data, params);
        try {
          const errorData = JSON.parse(data);
          if (errorData?.code === 404 || errorData.errors.length != 0) {
            yield put(atg.atg.fail(data));
          }
        } catch (error) {
          /* Create blob and link to download */
          const blob = b64toBlob(
            Buffer.from(data, "binary").toString("base64"),
            "application/zip"
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", params);

          /* Append to html page */
          document.body.appendChild(link);

          /* Force download */
          link.click();

          /* Clean up and remove the link */
          link.parentNode?.removeChild(link);
          yield put(atg.atg.success(data));
        }
      } catch (error) {
        yield put(atg.atg.fail(error));
        console.log(error);
      }
    },
  },
  masterCreditors: {
    get: function* (action: any) {
      const params = action?.payload.data ?? {};
      try {
        yield put(getCreditorGroups());
        yield take(GET_CREDITOR_GROUP_SUCCESS || GET_CREDITOR_GROUP_FAIL);
        const { creditorGroups } = yield select(
          (state: IRootState) => state.user
        );

        const groupIds = creditorGroups.map((group: any) => group.value);
        yield put(getCreditorCompanyByGroupId(groupIds));
        yield take(GET_CREDITOR_COMPANY_SUCCESS || GET_CREDITOR_COMPANY_FAIL);
        const { creditorCompanies } = yield select(
          (state: IRootState) => state.user
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default [
  takeLatest(ATG.SEARCH_ATG, ATGSagas.atgs.search),
  takeLatest(ATG.GET_ATG, ATGSagas.atg.download),
  takeLatest(ATG.GET_MASTER_CREDITOR, ATGSagas.masterCreditors.get),
];
