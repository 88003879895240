import { IAPIAction } from "us.collection/interfaces";
import { Debtor } from "us.collection.input-output/constants/Actions";

export const debtor: Readonly<IAPIAction> = {
    debtors: {
        get: (data) => ({
            type: Debtor.GET_DEBTORS_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Debtor.GET_DEBTORS_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Debtor.GET_DEBTORS_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    self: {
        get: (data) => ({
            type: Debtor.GET_DEBTOR_BY_NUMBER_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Debtor.GET_DEBTOR_BY_NUMBER_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Debtor.GET_DEBTOR_BY_NUMBER_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    }
}