import { call, put, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.input-output/actions";
import {
  Creditor,
  Debtor,
  Invoice,
} from "us.collection.input-output/constants/Actions";
import * as API from "us.collection.input-output/services";
import _ from "lodash";
import { $MessageBox } from "us.common/components";
import { setUsedInvoiceNo } from "us.collection.input-output/functions";
import { appInsights } from "us.helper";

const { creditor, debtor, invoice } = Actions;
const invoiceSagas = {
  creditors: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.entity.get, params);
        if (Array.isArray(data)) {
          yield put(creditor.creditors.success(data));
        } else {
          yield put(creditor.creditors.fail({}));
        }
      } catch (error) {
        appInsights.trackException(
          `Get Creditors invoice sagas exception. - ${error}`
        );
        yield put(creditor.creditors.fail(error as any));
      }
    },
  },
  creditor: {
    getId: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.entity.get, params);
        yield put(creditor.self.success(data));
      } catch (error) {
        appInsights.trackException(
          `Get Creditors by id invoice sagas exception. - ${error}`
        );
        yield put(creditor.self.fail(error as any));
      }
    },
  },
  debtors: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.entity.get, params);
        if (data?.length > 0) {
          yield put(debtor.debtors.success(data));
        } else {
          yield put(debtor.debtors.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `Get Debtors invoice sagas exception. - ${error}`
        );
        yield put(debtor.debtors.fail(error as any));
      }
    },
  },
  debtor: {
    getId: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.entity.get, params);
        yield put(debtor.self.success(data));
      } catch (error) {
        appInsights.trackException(
          `Get Debtor by id invoice sagas exception. - ${error}`
        );
        yield put(debtor.self.fail(error as any));
      }
    },
  },
  workflow: {
    get: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.workflow.get, params);
        if (Array.isArray(data)) {
          yield put(invoice.workflows.success(data));
        } else {
          yield put(invoice.workflows.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `Get Workflow invoice sagas exception. - ${error}`
        );
        yield put(invoice.workflows.fail([]));
      }
    },
  },
  invoice: {
    register: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.invoice.register, params);
        if (!data?.isError) {
          $MessageBox(
            "success",
            `US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.SUCCESSFULLY_REGISTERED`,
            "",
            ""
          );
          yield put(invoice.invoices.success(data));
        } else {
          if (
            data?.result?.errorList[0]?.errorText?.includes(
              "Invoice already registered"
            )
          ) {
            if (setUsedInvoiceNo(data).count === 1) {
              $MessageBox(
                "error",
                `US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.INVOICE_NO_IS_ALREADY_USED`,
                "",
                "",
                { invoiceNo: setUsedInvoiceNo(data).invoiceNos }
              );
            } else {
              $MessageBox(
                "error",
                `US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.INVOICE_NOS_ARE_ALREADY_USED`,
                "",
                "",
                { invoiceNo: setUsedInvoiceNo(data).invoiceNos }
              );
            }
          } else {
            $MessageBox(
              "error",
              `US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.REGISTRATION_FAIL`,
              "",
              ""
            );
          }
          yield put(invoice.invoices.fail(data));
        }
      } catch (error) {
        appInsights.trackException(
          `Register invoice sagas exception. - ${error}`
        );
        yield put(invoice.invoices.fail(error as any));
      }
    },
  },
  postalcode: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.postelArea.search, params);
        if (Array.isArray(data)) {
          yield put(invoice.postalCode.success(data));
        } else {
          yield put(invoice.postalCode.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `PostalCode search invoice sagas exception. - ${error}`
        );
        yield put(invoice.postalCode.fail([]));
      }
    },
  },
  municipality: {
    search: function* (action: any): any {
      const params = action.payload.data;
      try {
        const data = yield call(API.invoice.municipals.search, params);
        if (Array.isArray(data)) {
          yield put(invoice.minicipality.success(data));
        } else {
          yield put(invoice.minicipality.fail([]));
        }
      } catch (error) {
        appInsights.trackException(
          `Municipality search invoice sagas exception. - ${error}`
        );
        yield put(invoice.minicipality.fail([]));
      }
    },
  },
};

export default [
  takeLatest(Creditor.GET_CREDITORS_START, invoiceSagas.creditors.get),
  takeLatest(
    Creditor.GET_CREDITOR_BY_NUMBER_START,
    invoiceSagas.creditor.getId
  ),
  takeLatest(Debtor.GET_DEBTORS_START, invoiceSagas.debtors.get),
  takeLatest(Debtor.GET_DEBTOR_BY_NUMBER_START, invoiceSagas.debtor.getId),
  takeLatest(Invoice.GET_WORKFLOW_STATES_START, invoiceSagas.workflow.get),
  takeLatest(Invoice.REGISTER_INVOICE_START, invoiceSagas.invoice.register),
  takeLatest(Invoice.SEARCH_POSTALCODE_START, invoiceSagas.postalcode.search),
  takeLatest(
    Invoice.SEARCH_MUNICIPALITY_START,
    invoiceSagas.municipality.search
  ),
];
