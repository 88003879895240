import { IAPIAction } from "us.collection/interfaces";
import { Invoice } from "us.collection.input-output/constants/Actions";

export const invoice: Readonly<IAPIAction> = {
    workflows: {
        get: (data) => ({
            type: Invoice.GET_WORKFLOW_STATES_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Invoice.GET_WORKFLOW_STATES_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Invoice.GET_WORKFLOW_STATES_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    invoices: {
        save: (data) => ({
            type: Invoice.REGISTER_INVOICE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Invoice.REGISTER_INVOICE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Invoice.REGISTER_INVOICE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
        reset: (data) => ({
            type: Invoice.RESET_CLAIMS_DATA,
            payload: {
                data,
                isLoading: true
            }
        }),
    },
    postalCode: {
        search: (data) => ({
            type: Invoice.SEARCH_POSTALCODE_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Invoice.SEARCH_POSTALCODE_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Invoice.SEARCH_POSTALCODE_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
    minicipality: {
        search: (data) => ({
            type: Invoice.SEARCH_MUNICIPALITY_START,
            payload: {
                data,
                isLoading: true
            }
        }),
        success: (data) => ({
            type: Invoice.SEARCH_MUNICIPALITY_SUCCESS,
            payload: {
                data,
                isLoading: false
            }
        }),
        fail: (error) => ({
            type: Invoice.SEARCH_MUNICIPALITY_FAIL,
            payload: {
                error,
                isLoading: false
            }
        }),
    },
}