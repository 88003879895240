import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Common from "us.common";
import _ from "lodash";

const Creditor = (props: any) => {
  const {
    viewCreditors,
    searchCreditor,
    data,
    values,
    changeCreditorStatus,
    rest,
  } = props;
  const { $Input, $Row, $Col } = Common.Components;
  const { t } = useTranslation(["US.COLLECTION.INPUT-OUTPUT"], {
    useSuspense: true,
  });
  const [, , helpers] = useField("creditorNo");
  const isValidCreditor = useSelector(
    (state: any) => state.invoice.isValidCreditor
  );
  const { fullName, fullAddress } = data;

  return (
    <$Row gutter={8} className="mt-4">
      <$Col span={8}>
        <a
          onClick={viewCreditors}
          className="d-block"
          style={{ marginBottom: "2px" }}
        >
          <span className="required">*</span>
          <strong>
            {t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.CREDITOR")}
          </strong>
        </a>
        <$Input
          name="creditorNo"
          size="small"
          onBlur={(e: any) => {
            searchCreditor(e, values);
            helpers.setTouched(true);
          }}
          onChange={(e: any) => changeCreditorStatus(e, rest)}
        />
        {!isValidCreditor && (
          <div className="ant-form-item-explain ant-form-item-explain-error mt-n2 mb-1">
            {t("US.COLLECTION.INPUT-OUTPUT:REGISTERINVOICE.NO_CREDITOR_FOUND")}
          </div>
        )}
      </$Col>
      <$Col span={16}>
        <strong className="d-block" style={{ marginTop: "18px" }}>
          {fullName ?? ""}
        </strong>
        <small className="d-block bui-label">
          {fullAddress ?? ""}
        </small>
      </$Col>
    </$Row>
  );
};
export default Creditor;
