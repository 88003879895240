import {
  Creditor,
  Debtor,
  Invoice,
} from "us.collection.input-output/constants/Actions";
import { initialState, initailEntity } from "./State";
import {
  combineTwoFields,
  setContactNOs,
  combineAddress,
} from "us.collection.input-output/functions";
import _ from "lodash";

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case Creditor.GET_CREDITORS_START:
      return Object.assign({}, state, {
        creditors: {
          data: [],
          isLoading: true,
        },
      });
    case Creditor.GET_CREDITORS_SUCCESS:
      return Object.assign({}, state, {
        creditors: {
          data: _.sortBy(
            payload?.data?.map((item: any) => {
              return {
                ...item,
                fullName: combineTwoFields(item?.firstName, item?.lastName),
                fullAddress: combineAddress(item),
              };
            }),
            function (o) {
              return parseInt(o.creditorNo);
            }
          ),
          isLoading: false,
        },
      });
    case Creditor.GET_CREDITORS_FAIL:
      return Object.assign({}, state, {
        creditors: {
          data: [],
          isLoading: false,
        },
      });
    case Creditor.GET_CREDITOR_BY_NUMBER_START:
      return Object.assign({}, state, {
        formValues: payload?.data?.formValues,
      });
    case Creditor.GET_CREDITORS_BY_NUMBER_SUCCESS:
      return Object.assign({}, state, {
        creditor: {
          data:
            payload?.data?.length > 0
              ? {
                  ...state?.formValues,
                  creditorNo: payload?.data[0]?.creditorNo,
                  fullName: combineTwoFields(
                    payload?.data[0]?.firstName,
                    payload?.data[0]?.lastName
                  ),
                  fullAddress: combineAddress(payload?.data[0]),
                }
              : {
                  creditorNo: state?.formValues?.creditorNo,
                },
          isLoading: false,
        },
        isValidCreditor: payload?.data?.length > 0 ? true : false,
      });
    case Creditor.GET_CREDITORS_BY_NUMBER_FAIL:
      return Object.assign({}, state, {
        creditor: {
          data: {
            creditorNo: state?.formValues?.creditorNo,
          },
          isLoading: false,
        },
        isValidCreditor: false,
      });
    case Creditor.SET_CREDITOR_STATUS:
      return Object.assign({}, state, {
        isValidCreditor: payload?.data?.status,
      });
    case Debtor.GET_DEBTORS_START:
      return Object.assign({}, state, {
        debtors: {
          data: [],
          isLoading: true,
        },
      });
    case Debtor.GET_DEBTORS_SUCCESS:
      return Object.assign({}, state, {
        debtors: {
          data: payload?.data?.map((item: any, index: number) => {
            return {
              key: index,
              ...item,
              contacts: setContactNOs(item.phoneNumbers),
            };
          }),
          isLoading: false,
        },
      });
    case Debtor.GET_DEBTORS_FAIL:
      return Object.assign({}, state, {
        debtors: {
          data: [],
          isLoading: false,
        },
      });
    case Debtor.GET_DEBTOR_BY_NUMBER_START:
      return Object.assign({}, state, {
        formValues: payload?.data?.formValues,
      });
    case Debtor.GET_DEBTOR_BY_NUMBER_SUCCESS:
      const matchedDebtor = _.find(payload?.data, {
        creditorNo: state?.formValues?.creditorNo,
      });
      return Object.assign({}, state, {
        debtor: {
          data:
            payload?.data?.length > 0 && matchedDebtor != undefined
              ? {
                  ...matchedDebtor,
                  contacts: setContactNOs(matchedDebtor.phoneNumbers),
                }
              : {
                  ...initailEntity,
                  cid: state?.formValues?.cid,
                },
          isLoading: false,
        },
      });
    case Debtor.GET_DEBTOR_BY_NUMBER_FAIL:
      return Object.assign({}, state, {
        debtor: {
          data: {
            ...initailEntity,
            cid: state?.formValues?.cid,
          },
          isLoading: false,
        },
      });
    case Invoice.GET_WORKFLOW_STATES_START:
      return Object.assign({}, state, {
        workflows: {
          data: [],
          isLoading: true,
        },
      });
    case Invoice.GET_WORKFLOW_STATES_SUCCESS:
      return Object.assign({}, state, {
        workflows: {
          data: payload.data,
          isLoading: false,
        },
      });
    case Invoice.GET_WORKFLOW_STATES_FAIL:
      return Object.assign({}, state, {
        workflows: {
          data: [],
          isLoading: false,
        },
      });
    case Invoice.REGISTER_INVOICE_START:
      return Object.assign({}, state, {
        registeredCase: { ...state.registeredCase, isLoading: true },
      });
    case Invoice.REGISTER_INVOICE_SUCCESS:
      return Object.assign({}, state, {
        registeredCase: {
          data: [
            ...state.registeredCase.data,
            ...payload.data?.result?.importedBoxCaseList,
          ],
          isLoading: false,
        },
      });
    case Invoice.REGISTER_INVOICE_FAIL:
      return Object.assign({}, state, {
        registeredCase: { ...state.registeredCase, isLoading: false },
      });
    case Invoice.RESET_CLAIMS_DATA:
      return Object.assign({}, state, {
        registeredCase: {
          data: [],
          isLoading: false,
        },
      });

    case Invoice.SEARCH_POSTALCODE_START:
      return Object.assign({}, state, {
        postalcode: {
          data: [],
          isLoading: true,
        },
      });
    case Invoice.SEARCH_POSTALCODE_SUCCESS:
      return Object.assign({}, state, {
        postalcode: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case Invoice.SEARCH_POSTALCODE_FAIL:
      return Object.assign({}, state, {
        postalcode: {
          data: [],
          isLoading: false,
        },
      });

    case Invoice.SEARCH_MUNICIPALITY_START:
      return Object.assign({}, state, {
        municipility: {
          data: [],
          isLoading: true,
        },
      });
    case Invoice.SEARCH_MUNICIPALITY_SUCCESS:
      return Object.assign({}, state, {
        municipility: {
          data: payload?.data,
          isLoading: false,
        },
      });
    case Invoice.SEARCH_MUNICIPALITY_FAIL:
      return Object.assign({}, state, {
        municipility: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
