export default {
    server: window._ENV.REACT_APP_API_BASE_URL,
    endPoints: {
        sentences: {
            sentencesList: '/sentences'
        },
        notes: {
            notesList: '/notes'
        },
        extendedField: {
            extendedFieldList: '/extendedField'
        },
        enforcement: {
            enforcements: '/Enforcement'
        },
        systemService: {
            CaseDetails: '/CaseDetails',
            ARsEntInfo: '/ARsEntInfo'
        },
        metadata: {
            metadata: '/system/domainView/metadata'
        },
        arService: {
            caseProperties: '/cases'
        }
    }
}

export * from './PaymentAgreementActions';
export * from './TransactionType'
export * from './DomainView'
