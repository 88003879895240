import _ from "lodash";
import moment from "moment";
import { IFormData } from "us.collection.input-output/components/RegisterInvoice/Interfaces";
import { appInsights } from "us.helper";
/**
 * concat two values and filter undefined
 * @param value1 param 01
 * @param value2 param 02
 * @returns string
 */
export const combineTwoFields = (value1: any, value2: any) => {
  let result: string = "";
  try {
    if (value1 && value1?.toString().length > 0) {
      if (value2 && value2.toString().length > 0) {
        result = `${value1} ${value2}`;
      } else {
        result = value1;
      }
    } else if (value2) {
      result = value2;
    }
    return result;
  } catch (error) {
    appInsights.trackException(
      `Error in combineTwoFields function in invoice registration name creation. - ${error}`
    );
    return result;
  }
};

/**
 * Create Contact Object using phoneNumbers array that is given by entity detail response
 * @param data phoneNumbers array
 * @returns contact Object
 */
export const setContactNOs = (data: any[]) => {
  let nums: any = {};
  try {
    data?.map((item: any) => {
      nums[item.type] = item.number;
    });
    return nums;
  } catch (error) {
    appInsights.trackException(
      `Error in setContactNOs function in invoice registration contactNos creation. - ${error}`
    );
    return nums;
  }
};

/**
 * concat address1,address2, and address3 , zipcode and zipName
 * @param data Whole object
 * @returns address
 */
export const combineAddress = (data: any) => {
  let address: string = "";
  let postal: string = "";
  try {
    if (data?.address1 && data?.address1.length > 0) {
      address = `${data.address1},`;
    }
    if (data?.address2 && data?.address2.length > 0) {
      address = address.concat(` ${data.address2},`);
    }

    if (data?.address3 && data?.address3.length > 0) {
      address = address.concat(` ${data.address3},`);
    }

    if (data?.zipCode?.length > 0) {
      postal = data?.zipCode;
    }
    if (data?.zipName?.length > 0) {
      postal = postal.concat(`-${data?.zipName}`);
    }

    if (postal?.length > 0) {
      address = address.concat(` ${postal}`);
    } else {
      address = address.slice(0, -1);
    }
    return address;
  } catch (error) {
    appInsights.trackException(
      `Error in combineAddress function in invoice registration. - ${error}`
    );
    return address;
  }
};

/**
 * validation person number length
 * @param personNo {string} - person number
 * @returns {Object} person number validation and person number length
 */
const validatePersonNoLength = (
  personNo: string
): {
  isVaild: boolean;
  numberLength: number;
} => {
  let response = {
    isVaild: false,
    numberLength: 0,
  };
  if (personNo.length > 11) {
    response = {
      isVaild: false,
      numberLength: personNo.length,
    };
  } else if (personNo.length === 11) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else if (personNo.length >= 9) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else if (personNo.length === 6) {
    response = {
      isVaild: true,
      numberLength: personNo.length,
    };
  } else {
    response = {
      isVaild: false,
      numberLength: personNo.length,
    };
  }
  return response;
};

/**
 * Gerenate birthdate according to person number
 * @param personNo {string} person number
 * @returns {object} - birthdate ,month and day
 */
const generateBirthDate = (personNo: string) => {
  let day = parseInt(personNo.substring(0, 2));
  let month = parseInt(personNo.substring(2, 4));
  let year = personNo.substring(4, 6);
  let date: Date = new Date(moment().format());
  let yearCode: number = 0;
  try {
    if (personNo.length >= 9) {
      let centryCode = personNo.substring(6, 9);
      if (parseInt(centryCode) >= 0 && parseInt(centryCode) <= 499) {
        yearCode = parseInt(`19${year}`);
      } else if (parseInt(centryCode) >= 500 && parseInt(centryCode) <= 999) {
        yearCode = parseInt(`20${year}`);
      }
      date = new Date(yearCode, +month - 1, day);
    } else if (personNo.length === 6) {
      let currentYear = new Date().getFullYear().toString().substring(0, 2);
      let assumeYear = parseInt(currentYear + year);
      if (assumeYear > new Date().getFullYear()) {
        assumeYear = assumeYear - 100;
      }
      date = new Date(assumeYear, +month - 1, day);
    }
    return {
      date,
      month,
      day,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in generateBirthDate function in invoice registration. - ${error}`
    );
    return {
      date,
      month,
      day,
    };
  }
};

/**
 * Validate person number
 * @param personNumber {number | string} person number
 * @returns  {object} - person number validation and birthdate
 */
export const validatePersonNo = (
  personNumber: number | string
): {
  isValid: boolean;
  birthDate: string;
} => {
  let personNo = personNumber.toString();
  let validLength = validatePersonNoLength(personNo);
  let response = {
    isValid: false,
    birthDate: "",
  };
  try {
    if (validLength.isVaild) {
      let bDate = generateBirthDate(personNo);
      if (validLength.numberLength >= 9) {
        response = {
          isValid:
            Boolean(+bDate?.date) &&
            bDate?.date.getDate() == bDate.day &&
            bDate.date.getMonth() + 1 == bDate.month,
          birthDate: bDate.date.toString(),
        };
      } else if (validLength.numberLength === 6) {
        response = {
          isValid:
            Boolean(+bDate?.date) &&
            bDate?.date.getDate() == bDate.day &&
            bDate.date.getMonth() + 1 == bDate.month,
          birthDate: bDate.date.toString(),
        };
      }
    }
    if (
      moment().endOf("day") < moment(response.birthDate) &&
      response.isValid
    ) {
      return {
        isValid: false,
        birthDate: "",
      };
    }
    return response;
  } catch (error) {
    appInsights.trackException(
      `Error in validatePersonNo function in invoice registration. - ${error}`
    );
    return response;
  }
};

/**
 * Map form object
 * @param formData {IFormData} - form data
 * @returns {object}
 */
export const mapDataFormData = (formData: IFormData) => {
  try {
    return {
      entityId: "",
      entRoleId: "",
      roleType: "",
      cid: formData.cid,
      firstName: formData.firstName,
      lastName: formData.lastName,
      nIN: formData?.nIN,
      birthDate: formData.birthDate,
      address1: formData.address1,
      address2: formData.address2,
      address3: formData.address3,
      zipCode: formData.zipCode,
      zipName: formData.zipName,
      emailAddress: formData?.email,
      debtorType: formData?.debtorType,
      countryCode: formData.country,
      municipalityCode: formData?.municipalityCode,
      municipalityName: formData?.municipalityName,
      isAddressUnknown: false,
      reference: "",
      phoneNumbers: [],
      contacts: {
        Home: formData?.tel,
        sms: formData?.sms,
      },
    };
  } catch (error) {
    appInsights.trackException(
      `Error in mapDataFormData function in invoice registration. - ${error}`
    );
    return {
      entityId: "",
      entRoleId: "",
      roleType: "",
      cid: "",
      firstName: "",
      lastName: "",
      nIN: "",
      birthDate: "",
      address1: "",
      address2: "",
      address3: "",
      zipCode: "",
      zipName: "",
      emailAddress: "",
      debtorType: "",
      countryCode: "",
      municipalityCode: "",
      municipalityName: "",
      isAddressUnknown: false,
      reference: "",
      phoneNumbers: [],
      contacts: {
        Home: "",
        sms: "",
      },
    };
  }
};

/**
 * convert base64toBlob
 * @param {string} b64Data
 * @param {string} [contentType=""]
 * @param {number} [sliceSize=512]
 * @return {Blob}
 */
export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  try {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } catch (error) {
    appInsights.trackException(
      `Error in b64toBlob function in ATG download. - ${error}`
    );
    return b64Data;
  }
};

/**
 * Extract invoice numbers that are already used from API response
 * @param res API response
 * @returns {object}invoice numbers as string and count
 */
export const setUsedInvoiceNo = (
  res: any
): {
  invoiceNos: string;
  count: number;
} => {
  try {
    let result: Array<string> = [];
    res?.result?.errorList?.map((item: any) => {
      result.push(item?.errorText?.match(/Ref:(.*?)ARNo:/)[1]?.trim());
    });
    return {
      invoiceNos: result.join(),
      count: result.length,
    };
  } catch (error) {
    appInsights.trackException(
      `Error in setUsedInvoiceNo function in invoice registration. - ${error}`
    );
    return {
      invoiceNos: "",
      count: 0,
    };
  }
};

/**
 * Check Reference number is same as RF type reference number
 * @param transactions
 * @returns true- if both ref numbers ara equal,otherwise flase
 */
export const refNoValidate = (transactions: any): boolean => {
  try {
    const rfArr: Array<any> = _.compact(
      transactions
        .filter((transaction: any) => transaction.tType === "400")
        .map((transaction: any) => transaction.tNumber)
    );
    const refArr: Array<any> = _.compact(
      transactions
        .filter((transaction: any) => transaction.tType === "3")
        .map((transaction: any) => transaction.tNumber)
    );

    if (rfArr.length === 0 || refArr.length === 0) {
      return true;
    } else {
      return _.difference(rfArr, refArr).length === 0;
    }
  } catch (error) {
    appInsights.trackException(
      `Error in refNoValidate function in invoice registration. - ${error}`
    );
    return true;
  }
};
