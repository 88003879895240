import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from 'service.config.js';

export default {
    entity: {
        get: <
            P extends {
                roleType: string;
                searchCriteria: string;
                keyword: string
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "searchservice",
                        `entitysearch`,
                        {
                            roleType: params.roleType,
                            searchCriteria: params.searchCriteria,
                            keyword: params.keyword
                        },
                        ServiceConfig()[`searchservice`]['entitysearch']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    workflow: {
        get: <
            P extends {
                workflowEntity: string;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        "arservice",
                        `ars/workflowStates`,
                        {
                            workflowEntity: params.workflowEntity
                        },
                        ServiceConfig()[`arservice`]['ars.workflowStates']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    invoice: {
        register: (params: any): Promise<any> => {
            return new Promise<any>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.post(
                        "arservice",
                        "invoice",
                        params,
                        ServiceConfig()[`entityservice`]['entities.otherparty']
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    postelArea: {
        search: async <
            P extends {
                countryCode: string;
                searchText: string;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.post(
                        'entityservice',
                        `postalCodes`,
                        {
                            countryCode: params.countryCode,
                            searchText: params.searchText,
                            searchBy:"PostalCode"
                        },
                        ServiceConfig()[`entityservice`][`postalCodes`]
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    municipals: {
        search: async <
            P extends {
                countryCode: string;
                searchText: string;
            }
        >(
            params: P
        ): Promise<any> => {
            return new Promise<{}>(async (resolve, reject) => {
                try {
                    const { data } = await versionedHttpCollection.get(
                        'entityservice',
                        `entities/municipalities`,
                        {
                            countryCode: params.countryCode,
                            searchText: params.searchText,
                        },
                        ServiceConfig()[`entityservice`][`entities.municipalities`]
                    );
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    }
}