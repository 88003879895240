import { IAPIAction } from "us.collection/interfaces";
import { ATG } from "us.collection.input-output/constants/Actions";

export const atg: Readonly<IAPIAction> = {
  atgs: {
    search: (data) => ({
      type: ATG.SEARCH_ATG,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ATG.SEARCH_ATG_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ATG.SEARCH_ATG_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  atg: {
    get: (data) => ({
      type: ATG.GET_ATG,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ATG.GET_ATG_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ATG.GET_ATG_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  masterCreditors: {
    get: (data) => ({
      type: ATG.GET_MASTER_CREDITOR,
      payload: {
        data,
        isLoading: true
      }
    }),
    success: (data) => ({
      type: ATG.GET_MASTER_CREDITOR_SUCCESS,
      payload: {
        data,
        isLoading: false
      }
    }),
    fail: (error) => ({
      type: ATG.GET_MASTER_CREDITOR_FAIL,
      payload: {
        error,
        isLoading: false
      }
    })
  }
};
